import { useLayoutEffect } from 'react'
import * as THREE from 'three'
import { Canvas, invalidate, useGraph, useThree } from '@react-three/fiber'
import { OrbitControls, useFBX } from '@react-three/drei'
import { useCubeTexture } from '@react-three/drei'

import Camera from './Camera'

import useH3DStore from '../modules/useH3DStore'

import Model from './Model'

import {
  ambientLightSelector,
  backgroundHiddenSelector,
  getCameraCfg,
  getSrcs,
  hemisphereLightSelector
} from '../modules/modelStore'
import { rgb2Hex_3 } from '@pano/utils'

const HemisphereLight = () => {
  const hemisphereLight = useH3DStore(hemisphereLightSelector)

  return hemisphereLight.on ? (
    <hemisphereLight
      intensity={hemisphereLight.intensity}
      skyColor={rgb2Hex_3(
        hemisphereLight.skyColor.r * 255,
        hemisphereLight.skyColor.g * 255,
        hemisphereLight.skyColor.b * 255
      )}
      groundColor={rgb2Hex_3(
        hemisphereLight.groundColor.r * 255,
        hemisphereLight.groundColor.g * 255,
        hemisphereLight.groundColor.b * 255
      )}
    />
  ) : null
}

const AmbientLight = () => {
  const ambientLight = useH3DStore(ambientLightSelector)

  return ambientLight.on ? (
    <hemisphereLight
      intensity={ambientLight.intensity}
      color={rgb2Hex_3(
        ambientLight.color.r * 255,
        ambientLight.color.g * 255,
        ambientLight.color.b * 255
      )}
    />
  ) : null
}

const Background = ({ envMap }) => {
  const scene = useThree((st) => st.scene)
  const camera = useThree((st) => st.camera)

  const backgroundHidden = useH3DStore(backgroundHiddenSelector)

  useLayoutEffect(() => {
    scene.background = backgroundHidden ? null : envMap
    if (camera) {
      camera.fov += 0.01
      camera.updateProjectionMatrix()
      invalidate()
    }
  }, [scene, camera, envMap, backgroundHidden])
  return null
}
const Scene = () => {
  const { model, background } = useH3DStore(getSrcs)
  const envMap = useCubeTexture(background, { path: '/' })
  const scene = useFBX(model)
  // const ext = useFBX('Exterior1_viviendas.fbx')
  // const scene = useFBX('models/edificio1/model/model.fbx')
  // const scene = useFBX('models/truck/model/source/model.fbx')
  const { nodes, materials } = useGraph(scene)
  // const { nodes: nodes_ext, materials: materials_ext } = useGraph(ext)
  const { target } = useH3DStore(getCameraCfg)

  return (
    <Canvas
      style={{ backgroundColor: '#BBBBBB' }}
      frameloop={'demand'}
      gl={{
        preserveDrawingBuffer: true,
        antialias: true,
        encoding: THREE.sRGBEncoding
        // gammaFactor: 2.2
      }}
      flat
      linear={false}
      performance={{ min: 0.8, max: 1 }}
    >
      <OrbitControls
        makeDefault
        target={target}
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2}
        dampingFactor={0.25}
        regress
      />
      <Background envMap={envMap} />
      <HemisphereLight />
      <AmbientLight />
      <Camera />
      <Model
        model={scene}
        nodes={nodes}
        materials={materials}
        envMap={envMap}
      />
      {/* <primitive object={nodes_ext.Vivienda_005} /> */}
      {/* <primitive object={ext} /> */}
    </Canvas>
  )
}
export default Scene
