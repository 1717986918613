import { forwardRef, memo } from 'react'
import { styled } from '@mui/material/styles'

const Root = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'disabled' && prop !== 'readOnly' && prop !== 'textAlign'
})(({ theme, disabled, readOnly, textAlign }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign,
  width: '100%',
  color: disabled ? theme.palette.text.disabled : null,
  userSelect: 'none',
  boxSizing: 'border-box',
  padding: 4,
  minHeight: 0,
  cursor: disabled || readOnly ? null : 'pointer'
}))

const TextContent = forwardRef(
  ({ value, disabled, readOnly, textAlign, onClick, ...props }, ref) => {
    return (
      <Root
        ref={ref}
        disabled={disabled}
        readOnly={readOnly}
        textAlign={textAlign}
        onClick={onClick}
        {...props}
      >
        {value}
      </Root>
    )
  }
)
export default memo(TextContent)
