import { Suspense } from 'react'
import Scene from './Scene'

import useH3DStore from '../modules/useH3DStore'
import { getSrcs, loadingSelector } from '../modules/modelStore'
import { MyCircularProgress } from '@pano/components'

export const View = () => {
  const { model } = useH3DStore(getSrcs)
  const loading = useH3DStore(loadingSelector)

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: '#404040'
      }}
    >
      <MyCircularProgress loading={loading} delay={0} />
      <Suspense fallback={null}>{model && <Scene />}</Suspense>
    </div>
  )
}
export default View
