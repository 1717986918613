import { useEffect, useMemo } from 'react'
import {
  backgroundHiddenSelector,
  getMaterialMemo,
  objectsSelector,
  setModelAction
} from '../modules/modelStore'
import useH3DStore from '../modules/useH3DStore'

const Object = ({ id, materialId, nodes, envMap }) => {
  const gMaterialMemo = useMemo(getMaterialMemo, [])
  const objectMaterial = useH3DStore((st) => gMaterialMemo(st, materialId))
  const backgroundHidden = useH3DStore(backgroundHiddenSelector)
  const node = nodes[id]
  const material = node.material

  useEffect(() => {
    if (material) {
      material.needsUpdate = true
    }
  }, [objectMaterial, material])

  return (
    <mesh
      geometry={node.geometry}
      position={node.position}
      rotation={node.rotation}
      scale={node.scale}
    >
      <meshStandardMaterial
        map={material ? material.map : null}
        envMap={
          objectMaterial.values.envMap && !backgroundHidden ? envMap : null
        }
        transparent={objectMaterial.values.transparent}
        opacity={objectMaterial.values.opacity}
        color-r={objectMaterial.values.color.r}
        color-g={objectMaterial.values.color.g}
        color-b={objectMaterial.values.color.b}
        emissive-r={objectMaterial.values.emissive.r}
        emissive-g={objectMaterial.values.emissive.g}
        emissive-b={objectMaterial.values.emissive.b}
        roughness={objectMaterial.values.roughness}
        metalness={objectMaterial.values.metalness}
      />
    </mesh>
  )
}

export const Model = ({ model, nodes, materials, envMap }) => {
  const setModel = useH3DStore(setModelAction)
  const objects = useH3DStore(objectsSelector)

  useEffect(() => {
    if (model) {
      setModel(model, nodes, materials)
    }
  }, [model, nodes, materials, setModel])

  return objects.map((o) => {
    return o.visible ? (
      <Object
        key={o.id}
        id={o.id}
        materialId={o.materialId}
        nodes={nodes}
        envMap={envMap}
      />
    ) : null
  })

  // return <primitive object={model} />
}
export default Model
