import { createSelector } from 'reselect'
import * as THREE from 'three'
import { findById } from '../utils/utils'

// export const VALUE_IDS = {
//   COLOR: 'COLOR',
//   BOOLEAN: 'BOOLEAN',
//   FLOAT: 'FLOAT',
//   INT: 'INT'
// }
// export const PROPERTY_IDS = {
//   COLOR: 'color',
//   EMISSIVE: 'emissive',
//   SPECULAR: 'specular',
//   TRANSPARENT: 'transparent',
//   OPACITY: 'opacity',
//   SHININESS: 'shininess',
//   REFLECTIVITY: 'reflectivity',
//   REFRACTION_RATIO: 'refractionRatio'
// }
// export const PROPERTIES = {
//   [PROPERTY_IDS.COLOR]: {
//     id: PROPERTY_IDS.COLOR,
//     type: VALUE_IDS.COLOR,
//     name: 'Color'
//   },
//   [PROPERTY_IDS.EMISSIVE]: {
//     id: PROPERTY_IDS.EMISSIVE,
//     type: VALUE_IDS.COLOR,
//     name: 'Emissive'
//   },
//   [PROPERTY_IDS.SPECULAR]: {
//     id: PROPERTY_IDS.SPECULAR,
//     type: VALUE_IDS.COLOR,
//     name: 'Specular'
//   },
//   [PROPERTY_IDS.TRANSPARENT]: {
//     id: PROPERTY_IDS.TRANSPARENT,
//     type: VALUE_IDS.BOOLEAN,
//     name: 'Transparent'
//   },
//   [PROPERTY_IDS.OPACITY]: {
//     id: PROPERTY_IDS.OPACITY,
//     type: VALUE_IDS.FLOAT,
//     name: 'Opacity',
//     min: 0,
//     max: 1
//   },
//   [PROPERTY_IDS.SHININESS]: {
//     id: PROPERTY_IDS.SHININESS,
//     type: VALUE_IDS.FLOAT,
//     name: 'Shininess',
//     min: 0,
//     max: 1
//   },
//   [PROPERTY_IDS.REFLECTIVITY]: {
//     id: PROPERTY_IDS.REFLECTIVITY,
//     type: VALUE_IDS.FLOAT,
//     name: 'Reflectivity',
//     min: 0,
//     max: 1
//   },
//   [PROPERTY_IDS.REFRACTION_RATIO]: {
//     id: PROPERTY_IDS.REFRACTION_RATIO,
//     type: VALUE_IDS.FLOAT,
//     name: 'RefractionRatio',
//     min: 0,
//     max: 1
//   }
// }
// export const MATERIAL_IDS = {
//   STANDARD: 'MeshStandardMaterial',
//   BASIC: 'MeshBasicMaterial',
//   PHONG: 'MeshPhongMaterial'
// }
// export const MATERIALS = {
//   [MATERIAL_IDS.STANDARD]: {
//     id: MATERIAL_IDS.STANDARD,
//     name: '',
//     properties: []
//   },
//   [MATERIAL_IDS.BASIC]: {
//     id: MATERIAL_IDS.BASIC,
//     name: '',
//     properties: []
//   },
//   [MATERIAL_IDS.PHONG]: {
//     id: MATERIAL_IDS.PHONG,
//     name: '',
//     properties: [
//       PROPERTY_IDS.COLOR,
//       PROPERTY_IDS.EMISSIVE,
//       PROPERTY_IDS.SPECULAR,
//       PROPERTY_IDS.TRANSPARENT,
//       PROPERTY_IDS.OPACITY,
//       PROPERTY_IDS.SHININESS,
//       PROPERTY_IDS.REFLECTIVITY,
//       PROPERTY_IDS.REFRACTION_RATIO
//     ]
//   }
// }
const box = new THREE.Box3()

const resetState = () => ({
  model: { id: '' },
  hemisphereLight: {
    on: false,
    intensity: 0.5,
    skyColor: {
      r: 1, //[0-1]
      g: 1,
      b: 1
    },
    groundColor: {
      r: 1, //[0-1]
      g: 1,
      b: 1
    }
  },
  ambientLight: {
    on: false,
    intensity: 0.5,
    color: {
      r: 1, //[0-1]
      g: 1,
      b: 1
    }
  },
  materials: [],
  objects: [],
  currentObject: '',
  backgroundHidden: false,
  boundingBox: { min: [0, 0, 0], max: [0, 0, 0] },
  loading: false
})
export const modelStore = (set, get) => ({
  model: {
    models: [],
    ...resetState(),
    init: async () => {
      const response = await fetch('settings.json', {
        cache: 'no-cache'
      })
      if (response.ok) {
        const data = await response.json()
        set((prev) => ({
          model: { ...prev.model, ...resetState(), models: data.models }
        }))
      }
    },
    save: () => {
      const a = document.createElement('a')
      const file = new Blob([JSON.stringify(getOutput(get()))], {
        type: 'application/json'
      })
      a.href = URL.createObjectURL(file)
      a.download = `${currentModelIdSelector(get())}`
      a.click()
      a.remove()
    },
    setCurrentModel: (id) => {
      if (id !== currentModelIdSelector(get())) {
        set((prev) => ({
          model: {
            ...prev.model,
            ...resetState(),
            model: { ...prev.model.model, id },
            loading: true
          }
        }))
      }
    },
    setModel: (fbx, nodes, materials) => {
      if (fbx) {
        box.setFromObject(fbx)
        const boundingBox = {
          min: [box.min.x, box.min.y, box.min.z],
          max: [box.max.x, box.max.y, box.max.z]
        }

        let { objects, materials } = Object.keys(nodes).reduce(
          (acc, nodeId) => {
            const node = nodes[nodeId]
            const nodeMaterial = node.material
            acc.objects.push({
              id: nodeId,
              name: node.name,
              materialId: nodeMaterial?.name ? nodeMaterial.name : nodeId,
              visible: true
            })

            const material = {
              id: nodeMaterial?.name ? nodeMaterial.name : nodeId,
              originalId: nodeMaterial?.id ? nodeMaterial.id : '',
              name: nodeMaterial?.name
                ? nodeMaterial.name
                : `${nodeId} material`,
              values: {
                transparent: nodeMaterial?.transparent
                  ? nodeMaterial.transparent
                  : false,
                opacity:
                  nodeMaterial?.opacity !== undefined
                    ? nodeMaterial.opacity
                    : 1,
                color: nodeMaterial?.color
                  ? {
                      r: nodeMaterial.color.r,
                      g: nodeMaterial.color.g,
                      b: nodeMaterial.color.b
                    }
                  : { r: 1, g: 1, b: 1 },
                emissive: nodeMaterial?.emissive
                  ? {
                      r: nodeMaterial.emissive.r,
                      g: nodeMaterial.emissive.g,
                      b: nodeMaterial.emissive.b
                    }
                  : { r: 1, g: 1, b: 1 },
                roughness: nodeMaterial?.roughness ? nodeMaterial.roughness : 1,
                metalness: nodeMaterial?.metalness ? nodeMaterial.metalness : 0,
                envMap: nodeMaterial?.envMap ? nodeMaterial.envMap : false
              }
            }
            acc.materials.push(material)

            return acc
          },
          { objects: [], materials: [] }
        )

        set((prev) => ({
          model: {
            ...prev.model,
            boundingBox,
            objects,
            materials,
            loading: false
          }
        }))
      }
    },
    loadCfg: (data) => {
      if (data?.model.id === currentModelIdSelector(get())) {
        const materials = materialsSelector(get()).map((mat) => {
          const newMat = findById(data.materials, mat.id)
          return newMat ? newMat : mat
        })
        set((prev) => ({
          model: {
            ...prev.model,
            materials,
            hemisphereLight: data.hemisphereLight,
            ambientLight: data.ambientLight
          }
        }))
      }
    },
    setCurrentObject: (id) => {
      set((prev) => ({ model: { ...prev.model, currentObject: id } }))
    },
    setObjectProperty: (id, propertyId, value) => {
      set((prev) => ({
        model: {
          ...prev.model,
          objects: prev.model.objects.map((o) => {
            if (o.id === id) {
              return { ...o, [propertyId]: value }
            }
            return o
          })
        }
      }))
    },
    setMaterialProperty: (id, propertyId, value) => {
      set((prev) => ({
        model: {
          ...prev.model,
          materials: prev.model.materials.map((mat) => {
            if (mat.id === id) {
              return { ...mat, values: { ...mat.values, [propertyId]: value } }
            }
            return mat
          })
        }
      }))
    },
    setHemisphereLightProperty: (propertyId, value) => {
      set((prev) => ({
        model: {
          ...prev.model,
          hemisphereLight: {
            ...prev.model.hemisphereLight,
            [propertyId]: value
          }
        }
      }))
    },
    setAmbientLightProperty: (propertyId, value) => {
      set((prev) => ({
        model: {
          ...prev.model,
          ambientLight: { ...prev.model.ambientLight, [propertyId]: value }
        }
      }))
    },

    setProgress: (progress) => {
      set((prev) => ({ model: { ...prev.model, progress } }))
    },
    setBackgroundHidden: (hidden) => {
      set((prev) => ({ model: { ...prev.model, backgroundHidden: hidden } }))
    }
  }
})
export default modelStore

export const setCurrentModelAction = (state) => state.model.setCurrentModel
export const initAction = (state) => state.model.init
export const loadCfgAction = (state) => state.model.loadCfg
export const saveAction = (state) => state.model.save
export const setCurrentObjectAction = (state) => state.model.setCurrentObject
export const setModelAction = (state) => state.model.setModel
export const setModelSrcAction = (state) => state.model.setModelSrc
export const setProgressAction = (state) => state.model.setProgress
export const setObjectPropertyAction = (state) => state.model.setObjectProperty
export const setMaterialPropertyAction = (state) =>
  state.model.setMaterialProperty
export const setHemisphereLightPropertyAction = (state) =>
  state.model.setHemisphereLightProperty
export const setAmbientLightPropertyAction = (state) =>
  state.model.setAmbientLightProperty
export const setBackgroundHiddenAction = (state) =>
  state.model.setBackgroundHidden

export const modelsSelector = (state) => state.model.models
export const currentModelIdSelector = (state) => state.model.model.id

export const boundingBoxSelector = (state) => state.model.boundingBox
export const currentObjectSelector = (state) => state.model.currentObject
export const loadingSelector = (state) => state.model.loading
export const objectsSelector = (state) => state.model.objects
export const materialsSelector = (state) => state.model.materials
export const hemisphereLightSelector = (state) => state.model.hemisphereLight
export const ambientLightSelector = (state) => state.model.ambientLight
export const backgroundHiddenSelector = (state) => state.model.backgroundHidden

export const getCameraCfg = createSelector(
  [boundingBoxSelector],
  (boundingBox) => {
    const cfg = {
      target: [0, 0, 0],
      position: [0, 0, 0],
      near: 20,
      far: 0.001
    }
    if (boundingBox) {
      const xLength = boundingBox.max[0] - boundingBox.min[0]
      const yLength = boundingBox.max[1] - boundingBox.min[1]
      const zLength = boundingBox.max[2] - boundingBox.min[2]
      cfg.target[0] = boundingBox.min[0] + xLength / 2
      cfg.target[1] = boundingBox.min[1] + yLength / 2
      cfg.target[2] = boundingBox.min[2] + zLength / 2
      cfg.position[0] = cfg.target[0]
      cfg.position[1] = cfg.target[1]
      cfg.position[2] = cfg.target[2] + zLength
      // cfg.far = 10000
      // cfg.position[0] = xLength + boundingBox.max[0]
      // cfg.position[1] = yLength + boundingBox.max[1]
      // cfg.position[2] = zLength + boundingBox.max[2]
      cfg.far =
        6 *
        Math.sqrt(
          4 * xLength * xLength + 4 * yLength * yLength + 4 * zLength * zLength
        )
    }
    return cfg
  }
)

export const getMaterialMemo = () =>
  createSelector(
    [materialsSelector, (_, matId) => matId],
    (materials, matId) => {
      return findById(materials, matId)
    }
  )

export const getCurrentObject = createSelector(
  [objectsSelector, materialsSelector, currentObjectSelector],
  (objects, materials, currentObject) => {
    const object = findById(objects, currentObject)
    return {
      object,
      material: object ? findById(materials, object.materialId) : null
    }
  }
)

export const getSrcs = createSelector([currentModelIdSelector], (modelId) => {
  const base = `models/${modelId}`
  return {
    base: base,
    model: modelId ? `${base}/model/model.fbx` : '',
    background: modelId
      ? [
          `${base}/background/px.jpg`,
          `${base}/background/nx.jpg`,
          `${base}/background/py.jpg`,
          `${base}/background/ny.jpg`,
          `${base}/background/pz.jpg`,
          `${base}/background/nz.jpg`
        ]
      : []
  }
})

const getOutput = (state) => {
  return {
    model: { id: currentModelIdSelector(state) },
    hemisphereLight: hemisphereLightSelector(state),
    ambientLight: ambientLightSelector(state),
    materials: materialsSelector(state)
  }
}
