import { useCallback, useRef } from 'react'

import {
  ambientLightSelector,
  backgroundHiddenSelector,
  currentModelIdSelector,
  currentObjectSelector,
  getCurrentObject,
  hemisphereLightSelector,
  loadCfgAction,
  loadingSelector,
  modelsSelector,
  objectsSelector,
  saveAction,
  setAmbientLightPropertyAction,
  setBackgroundHiddenAction,
  setCurrentModelAction,
  setCurrentObjectAction,
  setHemisphereLightPropertyAction,
  setMaterialPropertyAction,
  setObjectPropertyAction
} from '../modules/modelStore'
import {
  PropertyTree,
  PropertyTreeSelectNode,
  SelectItem,
  PropertyTreeTwoStatesNode,
  PropertyTreeProvider,
  PropertyTreeEmptyNode,
  PropertyTreeFloatNode,
  PropertyTreeColorNode
} from '@pano/components'
import useH3DStore from '../modules/useH3DStore'
import { hex2Rgb_2, rgb2Hex_2 } from '@pano/utils'

export const HemisphereNode = ({ ...props }) => {
  const hemisphereLight = useH3DStore(hemisphereLightSelector)
  const setHemisphereLightProperty = useH3DStore(
    setHemisphereLightPropertyAction
  )
  const handleOn = useCallback(
    (nodeId, value) => {
      setHemisphereLightProperty('on', value)
    },
    [setHemisphereLightProperty]
  )
  const handleIntensity = useCallback(
    (nodeId, value) => {
      setHemisphereLightProperty('intensity', value)
    },
    [setHemisphereLightProperty]
  )
  const handleSky = useCallback(
    (nodeId, value) => {
      setHemisphereLightProperty('skyColor', hex2Rgb_2(value))
    },
    [setHemisphereLightProperty]
  )
  const handleGround = useCallback(
    (nodeId, value) => {
      setHemisphereLightProperty('groundColor', hex2Rgb_2(value))
    },
    [setHemisphereLightProperty]
  )

  return (
    <PropertyTreeEmptyNode label={'Hemisphere'} autoExpand {...props}>
      <PropertyTreeTwoStatesNode
        label={'On'}
        value={hemisphereLight.on}
        values={['False', 'True']}
        onChange={handleOn}
      />
      <PropertyTreeFloatNode
        label={'Intensity'}
        value={hemisphereLight.intensity}
        onChange={handleIntensity}
      />
      <PropertyTreeColorNode
        label={'SkyColor'}
        onChange={handleSky}
        disableAlpha
        value={rgb2Hex_2(
          hemisphereLight.skyColor.r * 255,
          hemisphereLight.skyColor.g * 255,
          hemisphereLight.skyColor.b * 255
        )}
      />
      <PropertyTreeColorNode
        label={'GroundColor'}
        onChange={handleGround}
        disableAlpha
        value={rgb2Hex_2(
          hemisphereLight.groundColor.r * 255,
          hemisphereLight.groundColor.g * 255,
          hemisphereLight.groundColor.b * 255
        )}
      />
    </PropertyTreeEmptyNode>
  )
}

export const AmbientNode = ({ ...props }) => {
  const ambientLight = useH3DStore(ambientLightSelector)
  const setAmbientLightProperty = useH3DStore(setAmbientLightPropertyAction)
  const handleOn = useCallback(
    (nodeId, value) => {
      setAmbientLightProperty('on', value)
    },
    [setAmbientLightProperty]
  )
  const handleIntensity = useCallback(
    (nodeId, value) => {
      setAmbientLightProperty('intensity', value)
    },
    [setAmbientLightProperty]
  )
  const handleColor = useCallback(
    (nodeId, value) => {
      setAmbientLightProperty('color', hex2Rgb_2(value))
    },
    [setAmbientLightProperty]
  )

  return (
    <PropertyTreeEmptyNode label={'Ambient'} autoExpand {...props}>
      <PropertyTreeTwoStatesNode
        label={'On'}
        value={ambientLight.on}
        values={['False', 'True']}
        onChange={handleOn}
      />
      <PropertyTreeFloatNode
        label={'Intensity'}
        value={ambientLight.intensity}
        onChange={handleIntensity}
      />
      <PropertyTreeColorNode
        label={'Color'}
        disableAlpha
        value={rgb2Hex_2(
          ambientLight.color.r * 255,
          ambientLight.color.g * 255,
          ambientLight.color.b * 255
        )}
        onChange={handleColor}
      />
    </PropertyTreeEmptyNode>
  )
}

export const ObjectsNode = ({ ...props }) => {
  const objects = useH3DStore(objectsSelector)
  const currentObject = useH3DStore(currentObjectSelector)
  const setCurrentObject = useH3DStore(setCurrentObjectAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      setCurrentObject(value)
    },
    [setCurrentObject]
  )
  return (
    <PropertyTreeSelectNode
      label={'Object'}
      value={currentObject}
      inputProps={{
        options: objects.map((o) => (
          <SelectItem key={o.id} value={o.id}>
            {o.name}
          </SelectItem>
        ))
      }}
      onChange={handleChange}
      autoExpand
      {...props}
    >
      {currentObject && <ObjectNode />}
    </PropertyTreeSelectNode>
  )
}

export const ObjectNode = ({ ...props }) => {
  const { object, material } = useH3DStore(getCurrentObject)
  const setObjectProperty = useH3DStore(setObjectPropertyAction)
  const setMaterialProperty = useH3DStore(setMaterialPropertyAction)
  // console.log(object, material)

  const handleVisible = useCallback(
    (nodeId, value) => {
      setObjectProperty(object.id, 'visible', value)
    },
    [setObjectProperty, object]
  )

  const handleTransparent = useCallback(
    (nodeId, value) => {
      setMaterialProperty(material.id, 'transparent', value)
    },
    [setMaterialProperty, material]
  )
  const handleOpacity = useCallback(
    (nodeId, value) => {
      setMaterialProperty(material.id, 'opacity', value)
    },
    [setMaterialProperty, material]
  )
  const handleRoughness = useCallback(
    (nodeId, value) => {
      setMaterialProperty(material.id, 'roughness', value)
    },
    [setMaterialProperty, material]
  )
  const handleMetalness = useCallback(
    (nodeId, value) => {
      setMaterialProperty(material.id, 'metalness', value)
    },
    [setMaterialProperty, material]
  )
  const handleColor = useCallback(
    (nodeId, value) => {
      setMaterialProperty(material.id, 'color', hex2Rgb_2(value))
    },
    [setMaterialProperty, material]
  )

  const handleEmissive = useCallback(
    (nodeId, value) => {
      setMaterialProperty(material.id, 'emissive', hex2Rgb_2(value))
    },
    [setMaterialProperty, material]
  )

  const handleEnvMap = useCallback(
    (nodeId, value) => {
      setMaterialProperty(material.id, 'envMap', value)
    },
    [setMaterialProperty, material]
  )
  return object && material ? (
    <>
      <PropertyTreeTwoStatesNode
        label={'Visible'}
        value={object.visible}
        values={['No', 'Yes']}
        onChange={handleVisible}
        {...props}
      />
      <PropertyTreeEmptyNode label={'Material'} autoExpand {...props}>
        <PropertyTreeTwoStatesNode
          label={'Transparent'}
          value={material.values.transparent}
          values={['No', 'Yes']}
          onChange={handleTransparent}
        />
        <PropertyTreeFloatNode
          label={'Opacity'}
          value={material.values.opacity}
          min={0}
          max={1}
          onChange={handleOpacity}
        />

        <PropertyTreeColorNode
          label={'Color'}
          disableAlpha
          value={rgb2Hex_2(
            material.values.color.r * 255,
            material.values.color.g * 255,
            material.values.color.b * 255
          )}
          onChange={handleColor}
        />
        <PropertyTreeColorNode
          label={'Emissive'}
          disableAlpha
          value={rgb2Hex_2(
            material.values.emissive.r * 255,
            material.values.emissive.g * 255,
            material.values.emissive.b * 255
          )}
          onChange={handleEmissive}
        />
        <PropertyTreeFloatNode
          label={'Roughness'}
          value={material.values.roughness}
          min={0}
          max={1}
          onChange={handleRoughness}
        />
        <PropertyTreeFloatNode
          label={'Metalness'}
          value={material.values.metalness}
          min={0}
          max={1}
          onChange={handleMetalness}
        />
        <PropertyTreeTwoStatesNode
          label={'EnvMap'}
          value={material.values.envMap}
          values={['No', 'Yes']}
          onChange={handleEnvMap}
        />
      </PropertyTreeEmptyNode>
    </>
  ) : null
}

export const BackgroundNode = ({ ...props }) => {
  const backgroundHidden = useH3DStore(backgroundHiddenSelector)
  const setBackgroundHidden = useH3DStore(setBackgroundHiddenAction)

  const handleVisible = useCallback(
    (nodeId, value) => {
      setBackgroundHidden(!value)
    },
    [setBackgroundHidden]
  )
  return (
    <PropertyTreeEmptyNode label={'Background'} autoExpand>
      <PropertyTreeTwoStatesNode
        label={'Visible'}
        value={!backgroundHidden}
        values={['No', 'Yes']}
        onChange={handleVisible}
        {...props}
      />
    </PropertyTreeEmptyNode>
  )
}

export const OpenButton = () => {
  const fileRef = useRef()
  const loadCfg = useH3DStore(loadCfgAction)

  const handleChange = (e) => {
    const fileReader = new FileReader()
    fileReader.readAsText(e.target.files[0], 'UTF-8')
    fileReader.onload = (e) => {
      const data = JSON.parse(fileReader.result)
      loadCfg(data)
    }
  }

  return (
    <>
      <button onClick={() => fileRef.current.click()}>Open</button>
      <input
        ref={fileRef}
        onChange={handleChange}
        multiple={false}
        type="file"
        accept=".json"
        hidden
      />
    </>
  )
}

export const SelectModelNode = ({ ...props }) => {
  const models = useH3DStore(modelsSelector)
  const currentModelId = useH3DStore(currentModelIdSelector)
  const setCurrentModel = useH3DStore(setCurrentModelAction)

  const handleChange = useCallback(
    (nodeId, value) => {
      setCurrentModel(value)
    },
    [setCurrentModel]
  )

  return (
    <PropertyTreeSelectNode
      label={'Model'}
      value={currentModelId}
      inputProps={{
        options: models.map((modelId) => (
          <SelectItem key={modelId} value={modelId}>
            {modelId}
          </SelectItem>
        ))
      }}
      onChange={handleChange}
      {...props}
    ></PropertyTreeSelectNode>
  )
}
export const Controls = () => {
  const modelId = useH3DStore(currentModelIdSelector)
  const loading = useH3DStore(loadingSelector)
  const save = useH3DStore(saveAction)
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        height: '100%'
      }}
    >
      <PropertyTreeProvider id={'main'}>
        <PropertyTree style={{ width: 480, height: '100%' }}>
          <SelectModelNode />
          {modelId && !loading && (
            <>
              <BackgroundNode />
              <PropertyTreeEmptyNode label={'Lights'} autoExpand>
                <HemisphereNode />
                <AmbientNode />
              </PropertyTreeEmptyNode>
              <ObjectsNode />
              <button onClick={() => save()}>Save</button>
              <OpenButton />
            </>
          )}
        </PropertyTree>
      </PropertyTreeProvider>
    </div>
  )
}
