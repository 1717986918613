import { styled } from '@mui/material/styles'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

const Root = styled('div')(({ theme, height }) => ({
  flex: '1 1 auto',
  height: '100%',
  minHeight: 0,
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.primary.main,
  border: height > 2 ? `1px solid ${theme.tree.borderColor}` : 'none'
}))

const Filler = () => {
  const [nodesBoundsRef, nodesBounds] = useMeasure({
    polyfill: ResizeObserver
  })
  return <Root ref={nodesBoundsRef} height={nodesBounds.height} />
}

export default Filler
