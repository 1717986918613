import { useCallback, memo } from 'react'

import PropertyTreeNode from '../Node/PropertyTreeNode'
import TextContent from './TextContent'

export const PropertyTreeTwoStatesNode = ({
  id,
  disabled,
  readOnly,
  value,
  values,
  textAlign,
  onChange,
  inputProps,
  ...props
}) => {
  const handleClick = useCallback(() => {
    !disabled && !readOnly && onChange && onChange(id, !Boolean(value))
  }, [onChange, disabled, readOnly, id, value])

  return (
    <PropertyTreeNode
      id={id}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      {...props}
      input={
        <TextContent
          disabled={disabled}
          readOnly={readOnly}
          textAlign={textAlign}
          onClick={handleClick}
          value={Boolean(value) ? values[1] : values[0]}
          {...inputProps}
        />
      }
    />
  )
}
export default memo(PropertyTreeTwoStatesNode)
