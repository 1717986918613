import { createTheme, lighten, darken } from '@mui/material/styles'

export const getPropertyTreeTheme = (baseTheme) => {
  const theme = createTheme({ ...baseTheme })
  if (!baseTheme?.tree) {
    theme.tree = {}
  }
  if (!baseTheme) {
    theme.tree.borderColor = '#303030'
  } else if (!baseTheme.tree?.borderColor) {
    theme.tree.borderColor = darken(baseTheme.palette.primary.contrastText, 0.6)
  }
  theme.components = {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        size: 'small',
        fullWidth: true,
        InputProps: { style: { margin: 0 } },
        hiddenLabel: true
      },
      styleOverrides: {
        root: {}
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
        disableUnderline: true,
        fullWidth: true,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }
      },
      styleOverrides: {
        root: {},
        icon: { color: 'inherit' },
        nativeInput: { boxSizing: 'border-box' }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiMenu-paper': {
            color: theme.palette.primary.contrastText,
            backgroundColor: lighten(theme.palette.primary.light, 0.05),
            borderRadius: 0,
            border: `1px solid ${theme.tree.borderColor}`,
            boxSizing: 'border-box',
            padding: 0,
            minWidth: 160,
            maxWidth: 460
          }
        },
        list: { padding: 0 }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          backgroundColor: 'transparent',
          '&.Mui-selected': {
            backgroundColor: lighten(theme.palette.primary.light, 0.1)
          },
          '&:hover': {
            backgroundColor: lighten(theme.palette.primary.light, 0.1),
            '&.Mui-selected': {
              backgroundColor: lighten(theme.palette.primary.light, 0.2)
            }
          }
        },
        divider: { borderColor: theme.palette.primary.contrastText }
      }
    },
    MuiInputLabel: { defaultProps: { shrink: false } },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          visibility: 'hidden'
        }
      }
    },
    MuiInput: {
      defaultProps: { disableUnderline: true },
      styleOverrides: {
        root: { color: theme.palette.primary.contrastText },
        input: {
          padding: 0
          // '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          //   WebkitAppearance: 'none',
          //   margin: 0
          // },
          // appearance: 'textfield'
        }
      }
    }
  }
  return theme
}
