import PropertyTreeNode from '../Node/PropertyTreeNode'
import FloatField from '../../Inputs/FloatField'

export const PropertyTreeFloatNode = ({
  id,
  readOnly,
  disabled,
  value,
  min,
  max,
  unitStart,
  unitEnd,
  textAlign,
  validateValue,
  onChange,
  inputProps,
  ...props
}) => {
  return (
    <PropertyTreeNode
      id={id}
      readOnly={readOnly}
      disabled={disabled}
      {...props}
      input={
        <FloatField
          id={id}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          textAlign={textAlign}
          validateValue={validateValue}
          unitEnd={unitEnd}
          unitStart={unitStart}
          {...inputProps}
        />
      }
    />
  )
}
export default PropertyTreeFloatNode
