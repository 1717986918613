import { cloneObject, clamp } from './utils'

export const SIDE_2D_IDS = {
  TOP: 'top',
  LEFT: 'left',
  BOTTOM: 'bottom',
  RIGHT: 'right'
}
export const REFERENCE_2D_IDS = {
  TOP_LEFT: 'tl',
  TOP_RIGHT: 'tr',
  BOTTOM_LEFT: 'bl',
  BOTTOM_RIGHT: 'br'
}

export const ANCHOR_2D_IDS = {
  TOP_LEFT: 'tl',
  TOP_CENTER: 'tc',
  TOP_RIGHT: 'tr',
  CENTER_LEFT: 'cl',
  CENTER: 'cc',
  CENTER_RIGHT: 'cr',
  BOTTOM_LEFT: 'bl',
  BOTTOM_CENTER: 'bc',
  BOTTOM_RIGHT: 'br'
}

export const SIDE_2D = {
  [SIDE_2D_IDS.TOP]: { id: SIDE_2D_IDS.TOP, name: 'Top', inc: 1 },
  [SIDE_2D_IDS.BOTTOM]: { id: SIDE_2D_IDS.LEFT, name: 'Bottom', inc: -1 },
  [SIDE_2D_IDS.LEFT]: { id: SIDE_2D_IDS, name: 'Left', inc: 1 },
  [SIDE_2D_IDS.RIGHT]: { id: SIDE_2D_IDS, name: 'Right', inc: -1 }
}

export const REFERENCES = {
  [REFERENCE_2D_IDS.TOP_LEFT]: {
    id: REFERENCE_2D_IDS.TOP_LEFT,
    name: 'Top-Left',
    sideX: SIDE_2D_IDS.LEFT,
    sideY: SIDE_2D_IDS.TOP
  },
  [REFERENCE_2D_IDS.TOP_RIGHT]: {
    id: REFERENCE_2D_IDS.TOP_RIGHT,
    name: 'Top-Right',
    sideX: SIDE_2D_IDS.RIGHT,
    sideY: SIDE_2D_IDS.TOP
  },
  [REFERENCE_2D_IDS.BOTTOM_LEFT]: {
    id: REFERENCE_2D_IDS.BOTTOM_LEFT,
    name: 'Bottom-Left',
    sideX: SIDE_2D_IDS.LEFT,
    sideY: SIDE_2D_IDS.BOTTOM
  },
  [REFERENCE_2D_IDS.BOTTOM_RIGHT]: {
    id: REFERENCE_2D_IDS.BOTTOM_RIGHT,
    name: 'Bottom-Right',
    sideX: SIDE_2D_IDS.RIGHT,
    sideY: SIDE_2D_IDS.BOTTOM
  }
}

export const ANCHORS = {
  [ANCHOR_2D_IDS.TOP_LEFT]: {
    id: ANCHOR_2D_IDS.TOP_LEFT,
    name: 'Top-Left',
    transformOrigin: 'top left',
    transforms: {
      [REFERENCE_2D_IDS.TOP_LEFT]: [0, 0],
      [REFERENCE_2D_IDS.TOP_RIGHT]: [100, 0],
      [REFERENCE_2D_IDS.BOTTOM_LEFT]: [0, 100],
      [REFERENCE_2D_IDS.BOTTOM_RIGHT]: [100, 100]
    },

    mark: (offsetX, offsetY) => ({
      top: 0,
      left: 0,
      right: null,
      bottom: null,
      transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`
    }),
    bar: (offsetX, offsetY) => ({
      top: -offsetY,
      left: 0,
      right: null,
      bottom: null,
      transform: `translate(-50%,-100%) `
    })
    // tag: {
    //   top: 0,
    //   left: 0,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-100%, -100%)'
    // },
    // hotspotsButton: {
    //   top: -20,
    //   left: null,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-50%, -100%)'
    // }
  },
  [ANCHOR_2D_IDS.TOP_CENTER]: {
    id: ANCHOR_2D_IDS.TOP_CENTER,
    name: 'Top-Center',
    transformOrigin: 'top center',
    transforms: {
      [REFERENCE_2D_IDS.TOP_LEFT]: [-50, 0],
      [REFERENCE_2D_IDS.TOP_RIGHT]: [50, 0],
      [REFERENCE_2D_IDS.BOTTOM_LEFT]: [-50, 100],
      [REFERENCE_2D_IDS.BOTTOM_RIGHT]: [50, 100]
    },
    // transforms3D: [0, 50],

    mark: (offsetX, offsetY) => ({
      top: 0,
      left: '50%',
      right: null,
      bottom: null,
      transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`
    }),
    bar: (offsetX, offsetY) => ({
      top: -offsetY,
      left: '50%',
      right: null,
      bottom: null,
      transform: `translate(-50%,-100%)`
    })

    // tag: {
    //   top: 0,
    //   left: '50%',
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-50%, -100%)'
    // },
    // hotspotsButton: {
    //   top: -20,
    //   left: null,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-50%, -100%)'
    // }
  },
  [ANCHOR_2D_IDS.TOP_RIGHT]: {
    id: ANCHOR_2D_IDS.TOP_RIGHT,
    name: 'Top-Right',
    transformOrigin: 'top right',
    transforms: {
      [REFERENCE_2D_IDS.TOP_LEFT]: [-100, 0],
      [REFERENCE_2D_IDS.TOP_RIGHT]: [0, 0],
      [REFERENCE_2D_IDS.BOTTOM_LEFT]: [-100, 100],
      [REFERENCE_2D_IDS.BOTTOM_RIGHT]: [0, 100]
    },
    // transforms3D: [-50, 50],

    mark: (offsetX, offsetY) => ({
      top: 0,
      left: '100%',
      right: null,
      bottom: null,
      transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`
    }),
    bar: (offsetX, offsetY) => ({
      top: -offsetY,
      left: '100%',
      right: null,
      bottom: null,
      transform: `translate(-50%,-100%)`
    })

    // tag: {
    //   top: 0,
    //   left: null,
    //   right: 0,
    //   bottom: null,
    //   transform: 'translate(100%, -100%)'
    // },
    // hotspotsButton: {
    //   top: -20,
    //   left: null,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-50%, -100%)'
    // }
  },
  [ANCHOR_2D_IDS.CENTER_LEFT]: {
    id: ANCHOR_2D_IDS.CENTER_LEFT,
    name: 'Center-Left',
    transformOrigin: 'center left',
    transforms: {
      [REFERENCE_2D_IDS.TOP_LEFT]: [0, -50],
      [REFERENCE_2D_IDS.TOP_RIGHT]: [100, -50],
      [REFERENCE_2D_IDS.BOTTOM_LEFT]: [0, 50],
      [REFERENCE_2D_IDS.BOTTOM_RIGHT]: [100, 50]
    },
    // transforms3D: [50, 0],

    mark: (offsetX, offsetY) => ({
      top: '50%',
      left: 0,
      right: null,
      bottom: null,
      transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`
    }),
    bar: (offsetX, offsetY) => ({
      top: '50%',
      left: -offsetY,
      right: null,
      bottom: null,
      transform: `translate(-100%,-50%)`
    })
    // tag: {
    //   top: '50%',
    //   left: 0,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-100%, -50%)'
    // },
    // hotspotsButton: {
    //   top: null,
    //   left: -20,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-100%, -50%)'
    // }
  },
  [ANCHOR_2D_IDS.CENTER]: {
    id: ANCHOR_2D_IDS.CENTER,
    name: 'Center',
    transformOrigin: 'center',
    transforms: {
      [REFERENCE_2D_IDS.TOP_LEFT]: [-50, -50],
      [REFERENCE_2D_IDS.TOP_RIGHT]: [50, -50],
      [REFERENCE_2D_IDS.BOTTOM_LEFT]: [-50, 50],
      [REFERENCE_2D_IDS.BOTTOM_RIGHT]: [50, 50]
    },
    // transforms3D: [0, 0],

    mark: (offsetX, offsetY) => ({
      top: '50%',
      left: '50%',
      right: null,
      bottom: null,
      transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`
    }),
    bar: (offsetX, offsetY) => ({
      top: '50%',
      left: '50%',
      right: null,
      bottom: null,
      transform: `translate(-50%,calc(-100% - ${offsetY}px))`
    })

    // tag: {
    //   top: '50%',
    //   left: '50%',
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-50%, -50%)'
    // },
    // hotspotsButton: {
    //   top: 8,
    //   left: null,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-50%, 50%)'
    // }
  },
  [ANCHOR_2D_IDS.CENTER_RIGHT]: {
    id: ANCHOR_2D_IDS.CENTER_RIGHT,
    name: 'Center-Right',
    transformOrigin: 'center right',
    transforms: {
      [REFERENCE_2D_IDS.TOP_LEFT]: [-100, -50],
      [REFERENCE_2D_IDS.TOP_RIGHT]: [0, -50],
      [REFERENCE_2D_IDS.BOTTOM_LEFT]: [-100, 50],
      [REFERENCE_2D_IDS.BOTTOM_RIGHT]: [0, 50]
    },
    // transforms3D: [-50, 0],

    mark: (offsetX, offsetY) => ({
      top: '50%',
      left: '100%',
      right: null,
      bottom: null,
      transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`
    }),
    bar: (offsetX, offsetY) => ({
      top: '50%',
      left: `100%`,
      right: null,
      bottom: null,
      transform: `translate(${offsetY}px,-50%)`
    })

    // tag: {
    //   top: '50%',
    //   left: null,
    //   right: 0,
    //   bottom: null,
    //   transform: 'translate(100%, -50%)'
    // },
    // hotspotsButton: {
    //   top: null,
    //   left: 20,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(0%, -50%)'
    // }
  },
  [ANCHOR_2D_IDS.BOTTOM_LEFT]: {
    id: ANCHOR_2D_IDS.BOTTOM_LEFT,
    name: 'Bottom-Left',
    transformOrigin: 'bottom left',
    transforms: {
      [REFERENCE_2D_IDS.TOP_LEFT]: [0, -100],
      [REFERENCE_2D_IDS.TOP_RIGHT]: [100, -100],
      [REFERENCE_2D_IDS.BOTTOM_LEFT]: [0, 0],
      [REFERENCE_2D_IDS.BOTTOM_RIGHT]: [100, 0]
    },
    // transforms3D: [50, -50],

    mark: (offsetX, offsetY) => ({
      top: '100%',
      left: 0,
      right: null,
      bottom: null,
      transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`
    }),
    bar: (offsetX, offsetY) => ({
      top: `calc(100% + ${offsetY}px)`,
      left: 0,
      right: null,
      bottom: null,
      transform: `translate(-50%,0%)`
    })

    // tag: {
    //   top: null,
    //   left: 0,
    //   right: null,
    //   bottom: 0,
    //   transform: 'translate(-100%, 100%)'
    // },
    // hotspotsButton: {
    //   top: 8,
    //   left: null,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-50%, 50%)'
    // }
  },
  [ANCHOR_2D_IDS.BOTTOM_CENTER]: {
    id: ANCHOR_2D_IDS.BOTTOM_CENTER,
    name: 'Bottom-Center',
    transformOrigin: 'bottom center',
    transforms: {
      [REFERENCE_2D_IDS.TOP_LEFT]: [-50, -100],
      [REFERENCE_2D_IDS.TOP_RIGHT]: [50, -100],
      [REFERENCE_2D_IDS.BOTTOM_LEFT]: [-50, 0],
      [REFERENCE_2D_IDS.BOTTOM_RIGHT]: [50, 0]
    },
    // transforms3D: [0, -50],
    mark: (offsetX, offsetY) => ({
      top: '100%',
      left: '50%',
      right: null,
      bottom: null,
      transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`
    }),
    bar: (offsetX, offsetY) => ({
      top: `calc(100% + ${offsetY}px)`,
      left: '50%',
      right: null,
      bottom: null,
      transform: `translate(-50%,0%)`
    })

    // tag: {
    //   top: null,
    //   left: '50%',
    //   right: null,
    //   bottom: 0,
    //   transform: 'translate(-50%, 100%)'
    // },
    // hotspotsButton: {
    //   top: 8,
    //   left: null,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-50%, 50%)'
    // }
  },
  [ANCHOR_2D_IDS.BOTTOM_RIGHT]: {
    id: ANCHOR_2D_IDS.BOTTOM_RIGHT,
    name: 'Bottom-Right',
    transformOrigin: 'bottom right',
    transforms: {
      [REFERENCE_2D_IDS.TOP_LEFT]: [-100, -100],
      [REFERENCE_2D_IDS.TOP_RIGHT]: [0, -100],
      [REFERENCE_2D_IDS.BOTTOM_LEFT]: [-100, 0],
      [REFERENCE_2D_IDS.BOTTOM_RIGHT]: [0, 0]
    },
    // transforms3D: [-50, -50],
    mark: (offsetX, offsetY) => ({
      top: '100%',
      left: '100%',
      right: null,
      bottom: null,
      transform: `translate(calc(-50% + ${offsetX}px), calc(-50% + ${offsetY}px))`
    }),
    bar: (offsetX, offsetY) => ({
      top: `calc(100% + ${offsetY}px)`,
      left: '100%',
      right: null,
      bottom: null,
      transform: `translate(-50%,0%)`
    })

    // tag: {
    //   top: null,
    //   left: null,
    //   right: 0,
    //   bottom: 0,
    //   transform: 'translate(100%, 100%)'
    // },
    // hotspotsButton: {
    //   top: 8,
    //   left: null,
    //   right: null,
    //   bottom: null,
    //   transform: 'translate(-50%, 50%)'
    // }
  }
}

const propertyValue = (prop) => {
  return prop.pixels ? prop.value : `${prop.value}%`
}
const getTransform = (tX, tY) => {
  return !tX && !tY ? null : `translate( ${tX}% , ${tY}%)`
}

export const setPropertyValue = (property, pixels, length) => {
  return {
    ...property,
    value: property.pixels ? pixels : (pixels * 100) / length
  }
}

export const incrementPropertyValue = (property, inc, length) => {
  return {
    ...property,
    value: property.pixels
      ? property.value + inc
      : property.value + (inc * 100) / length
  }
}

export const updatePosition = (currentPosition, newPosition, bounds) => {
  if (currentPosition.reference !== newPosition.reference) {
    return changeReference(newPosition.reference, currentPosition, bounds)
  }
  return JSON.parse(JSON.stringify(newPosition))
}

export const setPosition2DFromScreenCoords = (
  currentPosition2D,
  bounds,
  x,
  y
) => {
  if (currentPosition2D && bounds) {
    const reference = REFERENCES[currentPosition2D.reference]
    const position = { ...currentPosition2D }
    position[reference.properties[0].id] = setPropertyValue(
      position[reference.properties[0].id],
      reference.incX > 0 ? x : bounds.width - x,
      bounds.width
    )
    position[reference.properties[1].id] = setPropertyValue(
      position[reference.properties[1].id],
      reference.incY > 0 ? y : bounds.height - y,
      bounds.height
    )
    return position
  }
}

const validatePosition2DProperty = (property, length) => {
  const newProperty = cloneObject(property)
  if (newProperty.pixels) {
    newProperty.value = clamp(Math.round(newProperty.value), 0, length)
  } else {
    newProperty.value = clamp(newProperty.value, 0, 100)
  }
  return newProperty
}
export const validatePosition2D = (position, bounds) => {
  if (position) {
    const reference = REFERENCES[position.reference]
    const newPosition = { ...position }
    newPosition[reference.properties[0].id] = validatePosition2DProperty(
      position[reference.properties[0].id],
      bounds.width
    )
    newPosition[reference.properties[1].id] = validatePosition2DProperty(
      position[reference.properties[1].id],
      bounds.height
    )
    return newPosition
  }
  return null
}

export const getPosition = (position, parentSize) => {
  const reference = REFERENCES[position.reference]
  const xProp = position[reference.properties[0].id]
  const yProp = position[reference.properties[1].id]

  const left = xProp.pixels
    ? reference.incX > 0
      ? xProp.value
      : parentSize.width - xProp.value
    : Math.round(
        reference.incX > 0
          ? (parentSize.width * xProp.value) / 100
          : parentSize.width * (1 - xProp.value / 100)
      )
  const top = yProp.pixels
    ? reference.incY > 0
      ? yProp.value
      : parentSize.height - yProp.value
    : Math.round(
        reference.incY > 0
          ? (parentSize.height * yProp.value) / 100
          : parentSize.height * (1 - yProp.value / 100)
      )
  return [left, top]
}

export const getPositioning2D = (anchorId, position2D) => {
  const values = {
    top: null,
    left: null,
    bottom: null,
    right: null,
    transform: null,
    transformOrigin: null
  }

  if (position2D) {
    const reference = REFERENCES[position2D.reference]
    const anchor = ANCHORS[anchorId]
    values.transformOrigin = anchor.transformOrigin
    values[reference.sideX] = propertyValue(position2D.x)
    values[reference.sideY] = propertyValue(position2D.y)
    const transforms = anchor.transforms[reference.id]
    values.transform = getTransform(transforms[0], transforms[1])
    values.transformOrigin = anchor.transformOrigin
  }
  return values
}

export const getPositioning3D = (anchorId) => {
  const values = { transform: null }
  const anchor = ANCHORS[anchorId]
  const transforms = anchor.transforms3D
  values.transform = getTransform(transforms[0], transforms[1])
  return values
}

export const changeReference = (newReferenceId, oldPosition, bounds) => {
  //Cambio referencia 2D
  const newReference = newReferenceId
    ? REFERENCES[newReferenceId]
    : Object.values(REFERENCES)[0]

  let position2D = { reference: newReferenceId }
  //Recupera propiedades del valor actual y crea nuevas
  position2D[newReference.properties[0].id] = oldPosition[
    newReference.properties[0].id
  ]
    ? oldPosition[newReference.properties[0].id]
    : {
        value: 50,
        pixels: false
      }
  position2D[newReference.properties[1].id] = oldPosition[
    newReference.properties[1].id
  ]
    ? oldPosition[newReference.properties[1].id]
    : {
        value: 50,
        pixels: false
      }
  //Ajusta valores para que se mantenga en la misma posicion
  const pos = getPosition(oldPosition, bounds)
  return setPosition2DFromScreenCoords(position2D, bounds, pos[0], pos[1])
}

export const moveByDeltaPixels = (delta, position2D, bounds) => {
  const reference = position2D.reference
    ? REFERENCES[position2D.reference]
    : Object.values(REFERENCES)[0]

  const newPosition = { ...position2D }
  const sideX = SIDE_2D[reference.sideX]
  const sideY = SIDE_2D[reference.sideY]

  newPosition.x = incrementPropertyValue(
    position2D.x,
    delta[0] * sideX.inc,
    bounds.width
  )
  newPosition.y = incrementPropertyValue(
    position2D.y,
    delta[1] * sideY.inc,
    bounds.height
  )
  return newPosition
}
// export const moveByDeltaPixels = (delta, position2D, bounds) => {
//   const reference = position2D.reference
//     ? REFERENCES[position2D.reference]
//     : Object.values(REFERENCES)[0]
//   const newPosition = { ...position2D }
//   reference.properties.forEach((prop) => {
//     newPosition[prop.id] = incrementPropertyValue(
//       position2D[prop.id],
//       prop.length === 'height'
//         ? delta[1] * reference.incY
//         : delta[0] * reference.incX,
//       bounds[prop.length]
//     )
//   })
//   return newPosition
// }
