import {
  useMemo,
  useState,
  useEffect,
  cloneElement,
  Children,
  memo
} from 'react'
import { styled, useTheme, alpha, lighten } from '@mui/material/styles'

import PropertyTreeRow from '../Tree/PropertyTreeRow'
import PropertyTreeNodeLabel from './PropertyTreeNodeLabel'
import PropertyTreeNodeContent from './PropertyTreeNodeContent'
import Splitter from '../Splitter/Splitter'

import {
  getNodeDataMemo,
  leftWidthSelector,
  nodeCreatedAction,
  setNodeExpandedAction
} from '../modules/propertyTreeStore'
import usePropertyTree from '../modules/usePropertyTree'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column'
}))

const NodeWrapper = styled(PropertyTreeRow, {
  shouldForwardProp: (prop) => prop !== 'level'
})(({ theme, height, disabled, level, color }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'row',
  boxSizing: 'border-box',
  height: height,
  maxHeight: height,
  minHeight: height,
  width: '100%',
  backgroundColor: lighten(color, level * 0.05),
  color: disabled
    ? theme.palette.text.disabled
    : theme.palette.primary.contrastText,
  padding: 1,

  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1)
    // border: `0px solid ${theme.palette.secondary.main}`
  }
}))

const ChildrenWrapper = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  minHeight: 0
  // minHeight: 200
}))

const PropertyTreeNode = memo(
  ({
    id,
    label,
    tooltip,
    tooltipDisabled,
    level = 0,
    disabled,
    warning,
    error,
    readOnly,
    autoExpand,
    children,
    checkbox,
    checked,
    leftElements,
    input,
    rightElements,
    onChecked,
    ...props
  }) => {
    const nodeCreated = usePropertyTree(nodeCreatedAction)
    const gNodeDataMemo = useMemo(getNodeDataMemo, [])
    const { expanded, created, height } = usePropertyTree((st) =>
      gNodeDataMemo(st, id)
    )

    const leftWidth = usePropertyTree(leftWidthSelector)
    const setNodeExpanded = usePropertyTree(setNodeExpandedAction)
    const [localLeftWidth, setLocalLeftWidth] = useState(0)

    const theme = useTheme()
    const hijos = useMemo(() => {
      return children ? Children.toArray(children).filter((child) => child) : []
    }, [children])

    // console.log('node', id, level)

    useEffect(() => {
      if (!created) {
        nodeCreated(id)
        if (autoExpand) {
          setNodeExpanded(id, true)
        }
      }
    }, [nodeCreated, id, created, setNodeExpanded, autoExpand])

    useEffect(() => {
      if (localLeftWidth === 0 && leftWidth) {
        setLocalLeftWidth(leftWidth)
      }
    }, [localLeftWidth, leftWidth])

    const color = error
      ? theme.palette.error.light
      : warning
      ? theme.palette.warning.light
      : lighten(theme.palette.primary.light, level * 0.05)

    return (
      <Root>
        <NodeWrapper
          height={height}
          disabled={disabled}
          level={level}
          color={color}
          {...props}
        >
          <PropertyTreeNodeLabel
            id={id}
            hasChildren={hijos.length}
            label={label}
            level={level}
            tooltip={tooltip}
            tooltipDisabled={tooltipDisabled}
            hasInput={Boolean(input)}
            checkbox={checkbox}
            checked={checked}
            expanded={expanded}
            onChecked={onChecked}
          />
          {input && (
            <>
              <Splitter />
              <PropertyTreeNodeContent
                id={id}
                expanded={expanded}
                readOnly={readOnly}
                disabled={disabled}
                leftElements={leftElements}
                rightElements={rightElements}
                input={input}
              />
            </>
          )}
        </NodeWrapper>
        {expanded && (
          <ChildrenWrapper>
            {hijos.map((node, idx) =>
              cloneElement(node, {
                key: idx,
                level: level + 1,
                id: `${id}:${node.props.id ? node.props.id : `n${idx}`}`,
                idx
              })
            )}
          </ChildrenWrapper>
        )}
      </Root>
    )
  }
)
export default PropertyTreeNode
