import { createTheme, lighten } from '@mui/material/styles'

// import createBreakpoints from '@material-ui/styles/createBreakpoints'
// const breakpoints = createBreakpoints({})

const MainTheme = createTheme({
  typography: {
    fontFamily: `Metropolis-Regular ,Roboto, Helvetica, Arial, sans-serif`,
    h1: { fontSize: '28px' },
    h2: { fontSize: '24px' },
    h3: { fontSize: '20px' },
    h4: { fontSize: '16px' },
    h5: { fontSize: '12px' },
    h6: { fontSize: '8px' }
  },
  palette: {
    borderColor: '#606060',
    resizer: { border: '#606060', dragger: '#303030', anchor: '#FF0000' },
    error: { main: '#eb5757' },
    primary: {
      main: '#000000',
      mainTransparent: 'rgba(0, 0, 0, 0.5)',
      light: '#333333',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#D3AC5E',
      contrastText: '#FFFFFF',
      gradient: 'linear-gradient(to top, #d3ac5e, #ead98a)',
      gradientHorizontal: 'linear-gradient(to right, #d3ac5e, #ead98a)',
      hover: '#575343E0'
    },
    action: { disabled: '#828282' },
    other: {
      background: '#000000'
      // background: '#000F31',
      // sendButton: '#e0e0e0'
    }
  },
  tree: {}
})

MainTheme.tree = { borderColor: MainTheme.palette.borderColor }
MainTheme.scrollbar = {
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '8px',
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: MainTheme.palette.primary.contrastText,
    borderRadius: 10
  }
}
MainTheme.components = {
  MuiTextField: {
    defaultProps: { size: 'small', variant: 'outlined' },
    styleOverrides: {
      root: {
        '& .MuiFormLabel-root': {
          color: MainTheme.palette.primary.contrastText,
          '&.Mui-focused': {
            color: MainTheme.palette.secondary.main
          }
        },
        '& .MuiOutlinedInput-root': {
          color: MainTheme.palette.primary.contrastText,
          '& fieldset': {
            borderColor: MainTheme.palette.primary.contrastText
          },
          '&:hover fieldset': {
            borderColor: MainTheme.palette.secondary.main
          },
          '&.Mui-focused fieldset': {
            borderColor: MainTheme.palette.secondary.main
          }
        },
        '& .MuiSelect-icon': { color: MainTheme.palette.primary.contrastText }
      }
    }
  }
}

export default MainTheme
