import { styled } from '@mui/material/styles'
import { InputAdornment } from '@mui/material'

const Root = styled(InputAdornment)(({ theme }) => ({
  color: 'currentColor'
  // marginRight: '2px',
  // marginLeft: '2px'
}))

const Unit = ({ children, start, ...props }) => {
  return (
    <Root disableTypography position={start ? 'start' : 'end'} {...props}>
      {children}
    </Root>
  )
}

export default Unit
