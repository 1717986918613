import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useGesture } from '@use-gesture/react'

import {
  setLeftWidthAction,
  fixedSelector,
  leftWidthSelector
} from '../modules/propertyTreeStore'
import usePropertyTree from '../modules/usePropertyTree'

import { SPLITTER_TRANSPARENTBAR_WIDTH } from '../constants'
const SPLITTER_BAR_WIDTH = 1

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'leftWidth' && prop !== 'fixed'
})(({ theme, leftWidth, fixed }) => ({
  position: 'absolute',
  top: 0,
  left: leftWidth,
  cursor: fixed ? null : 'col-resize',
  height: '100%',
  touchAction: 'none'
}))

const SplitterBar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fixed'
})(({ theme, fixed }) => ({
  boxSizing: 'border-box',
  width: SPLITTER_BAR_WIDTH,
  minWidth: SPLITTER_BAR_WIDTH,
  height: '100%',
  cursor: fixed ? null : 'col-resize',

  backgroundColor: theme.tree.borderColor
}))

const SplitterTransparentBar = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'left' && prop !== 'transparentBarWidth' && prop !== 'fixed'
})(({ theme, left, transparentBarWidth, fixed }) => ({
  position: 'absolute',
  left: left ? -transparentBarWidth : SPLITTER_BAR_WIDTH,
  top: 0,
  width: transparentBarWidth,
  minWidth: transparentBarWidth,
  height: '100%',
  cursor: fixed ? null : 'col-resize',
  backgroundColor: 'transparent'
}))

const Splitter = () => {
  const [localLeftWidth, setLocalLeftWidth] = useState(0)
  const [first, setFirst] = useState(true)
  const fixed = usePropertyTree(fixedSelector)
  const leftWidth = usePropertyTree(leftWidthSelector)
  const setLeftWidth = usePropertyTree(setLeftWidthAction)

  const bind = useGesture({
    onDrag: ({ movement }) => {
      if (!fixed) {
        if (first) {
          setFirst(false)
          setLocalLeftWidth(leftWidth)
          setLeftWidth(leftWidth + movement[0])
        } else {
          setLeftWidth(localLeftWidth + movement[0])
        }
      }
    },
    onDragEnd: () => {
      if (!fixed) {
        setFirst(true)
      }
    }
  })

  return (
    <Root {...bind()} leftWidth={leftWidth} fixed={fixed}>
      <SplitterTransparentBar
        left
        transparentBarWidth={SPLITTER_TRANSPARENTBAR_WIDTH}
        fixed={fixed}
      />
      <SplitterBar fixed={fixed} />
      <SplitterTransparentBar
        transparentBarWidth={SPLITTER_TRANSPARENTBAR_WIDTH}
        fixed={fixed}
      />
    </Root>
  )
}
export default Splitter
