import { styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.tree.borderColor}`
}))

const PropertyTreeRow = ({ children, ...props }) => {
  return <Root {...props}>{children}</Root>
}
export default PropertyTreeRow
