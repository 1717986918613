import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useRef, useState } from 'react'

const Root = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'radius' && prop !== 'zIndex'
})(({ theme, radius, zIndex }) => ({
  position: 'absolute',
  zIndex: zIndex,
  top: `calc(50% - ${radius}px)`,
  left: `calc(50% - ${radius}px)`
}))

export const MyCircularProgress = ({
  loading,
  radius = 40,
  zIndex = 1,
  delay = 500,
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  const timerIdRef = useRef(null)

  useEffect(() => {
    if (loading) {
      if (!timerIdRef.current) {
        timerIdRef.current = setTimeout(() => {
          setVisible(true)
        }, delay)
      }
    } else {
      timerIdRef.current && clearTimeout(timerIdRef.current)
      timerIdRef.current = null
      setVisible(false)
    }
  }, [loading, delay])

  return visible ? (
    <Root size={radius * 2} radius={radius} zIndex={zIndex} {...props} />
  ) : null
}
// const RotatePanZoomProgress = ({ loading }) => {
//   const mode = useStore(rotatorModeSelector)
//   return mode === ROTATOR_MODE.LOADING_FIRST ||
//     mode === ROTATOR_MODE.LOADING ? (
//     <Root size={PROGRESS_RADIUS * 2} />
//   ) : null
// }
export default MyCircularProgress
