import { useCallback, useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { Checkbox } from '@mui/material'

import Icon from '../../Icons/Icons'

import {
  setNodeExpandedAction,
  leftWidthSelector
} from '../modules/propertyTreeStore'
import usePropertyTree from '../modules/usePropertyTree'

import { ICON_IDS } from '../../Icons/constants'
import { SPLITTER_TRANSPARENTBAR_WIDTH } from '../constants'
const Root = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'hasChildren' &&
    prop !== 'hasInput' &&
    prop !== 'level' &&
    prop !== 'leftWidth' &&
    prop !== 'transparentBarWidth'
})(
  ({
    theme,
    hasChildren,
    hasInput,
    level,
    leftWidth,
    transparentBarWidth
  }) => ({
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingLeft: hasChildren ? level * 8 : 24 + level * 8,
    paddingRight: transparentBarWidth,
    width: hasInput ? leftWidth - 3 : '100%',
    minWidth: leftWidth - 3,
    cursor: hasChildren ? 'pointer' : 'default'
  })
)

const Label = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'start',
  userSelect: 'none'
}))

const ExpandIconWrapper = styled('div')(({ theme }) => ({
  padding: 4,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const SCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  color: 'currentColor',
  '&.Mui-checked': {
    color: 'currentColor'
  }
}))

const CheckboxWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: 0,
  paddingRight: 12
}))

const PropertyTreeNodeLabel = ({
  id,
  expanded,
  hover,
  hasChildren,
  hasInput,
  label,
  level,
  tooltip,
  tooltipDisabled,
  checkbox,
  checked,
  onChecked
}) => {
  const [localLeftWidth, setLocalLeftWidth] = useState(0)
  const setNodeExpanded = usePropertyTree(setNodeExpandedAction)
  const leftWidth = usePropertyTree(leftWidthSelector)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (localLeftWidth === 0 && leftWidth) {
      setLocalLeftWidth(leftWidth)
    }
  }, [leftWidth, localLeftWidth])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleExpand = useCallback(() => {
    setOpen(false)
    hasChildren && setNodeExpanded(id, !expanded)
  }, [setNodeExpanded, hasChildren, expanded, id])

  const handleChecked = useCallback(
    (e) => {
      onChecked && onChecked(id, e.target.checked)
    },
    [onChecked, id]
  )

  return (
    <Tooltip
      title={tooltip ? tooltip : ''}
      open={open && !tooltipDisabled && Boolean(tooltip)}
      onClose={handleClose}
      onOpen={handleOpen}
      disableInteractive
    >
      <Root
        level={level}
        hasChildren={hasChildren}
        hasInput={hasInput}
        leftWidth={leftWidth}
        onClick={handleExpand}
        transparentBarWidth={SPLITTER_TRANSPARENTBAR_WIDTH}
      >
        {hasChildren ? (
          expanded ? (
            <ExpandIconWrapper>
              <Icon id={ICON_IDS.TREE_COLLAPSE} sx={{ width: 16 }} />
            </ExpandIconWrapper>
          ) : (
            <ExpandIconWrapper>
              <Icon id={ICON_IDS.TREE_EXPAND} sx={{ width: 16 }} />
            </ExpandIconWrapper>
          )
        ) : null}

        {checkbox ? (
          <CheckboxWrapper>
            <Label>{label}</Label>
            <SCheckbox
              size="small"
              checked={checked}
              disableRipple
              onClick={(e) => e.stopPropagation()}
              onChange={handleChecked}
            />
          </CheckboxWrapper>
        ) : (
          <Label>{label}</Label>
        )}
      </Root>
    </Tooltip>
  )
}
export default PropertyTreeNodeLabel
