import { Children, isValidElement } from 'react'
import { isFragment } from 'react-is'

export const getReactNodeChildren = (children) => {
  return Children.toArray(children).reduce((acc, node) => {
    if (isFragment(node)) {
      acc.push.apply(acc, getReactNodeChildren(node.props.children))
    } else {
      if (isValidElement(node)) {
        acc.push(node)
      } else if (typeof node === 'string' || typeof node === 'number') {
        acc.push(node)
      }
    }
    return acc
  }, [])
}
