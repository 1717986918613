import ColorField from '../../Inputs/ColorField'
import PropertyTreeNode from '../Node/PropertyTreeNode'

export const PropertyTreeColorNode = ({
  id,
  readOnly,
  disabled,
  value,
  disableAlpha,
  validateValue,
  onChange,
  inputProps,
  ...props
}) => {
  return (
    <PropertyTreeNode
      id={id}
      readOnly={readOnly}
      disabled={disabled}
      {...props}
      input={
        <ColorField
          id={id}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          validateValue={validateValue}
          disableAlpha={disableAlpha}
          onChange={onChange}
          {...inputProps}
        />
      }
    />
  )
}
export default PropertyTreeColorNode
