import { useEffect, useState, useCallback } from 'react'
// import { styled } from '@mui/material/styles'

import BaseTextField from './BaseTextField'
import Unit from './Unit'

const FloatField = ({
  value,
  min,
  max,
  unitStart,
  unitEnd,
  readOnly,
  disabled,
  textAlign,
  validateValue,
  onChange,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(0)
  const [localMin, setLocalMin] = useState(0)
  const [localMax, setLocalMax] = useState(0)
  const [editing, setEditing] = useState(false)

  const getFloatValue = useCallback(
    (v) => {
      let floatValue = parseFloat(v)
      if (isNaN(floatValue)) {
        floatValue = parseFloat(value)
      }
      floatValue = Math.max(localMin, floatValue)
      floatValue = Math.min(localMax, floatValue)
      return floatValue
    },
    [localMin, localMax, value]
  )

  useEffect(() => {
    setLocalMin(isNaN(min) || min === null ? -Number.MAX_VALUE : min)
    setLocalMax(isNaN(max) || max === null ? Number.MAX_VALUE : max)

    setLocalValue(getFloatValue(value))
  }, [value, min, max, getFloatValue, localValue])

  const handleEditingChange = useCallback((editing) => {
    setEditing(editing)
  }, [])

  const handleChange = useCallback(
    (id, value) => {
      setLocalValue(value)
      onChange && onChange(id, value)
    },
    [onChange]
  )

  return (
    <>
      {editing && !readOnly ? (
        <BaseTextField
          type={'number'}
          value={localValue}
          inputProps={{
            sx: {
              textAlign,
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0
              },
              appearance: 'textfield'
            },
            readOnly: false
          }}
          InputProps={{
            endAdornment: unitEnd && <Unit>{unitEnd}</Unit>,
            startAdornment: unitStart && <Unit start>{unitStart}</Unit>
          }}
          validateValue={getFloatValue}
          onChange={handleChange}
          onEditingChange={handleEditingChange}
          {...props}
        />
      ) : (
        <BaseTextField
          type={'text'}
          value={Intl.NumberFormat().format(localValue)}
          onChange={onChange}
          onEditingChange={handleEditingChange}
          disabled={disabled}
          inputProps={{
            sx: {
              textAlign
            },
            readOnly: Boolean(readOnly)
          }}
          InputProps={{
            endAdornment: unitEnd && <Unit>{unitEnd}</Unit>,
            startAdornment: unitStart && <Unit start>{unitStart}</Unit>
          }}
          {...props}
        />
      )}
    </>
  )
}

export default FloatField
//
