import { styled, lighten } from '@mui/material/styles'
import { useScrollToolbarContext } from './ScrollToolbarContext'

const Root = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'selected' &&
    prop !== 'disabled' &&
    prop !== 'button' &&
    prop !== 'length' &&
    prop !== 'vertical' &&
    prop !== 'width' &&
    prop !== 'height'
})(
  ({ theme, width, height, selected, disabled, button, length, vertical }) => ({
    boxSizing: 'border-box',
    padding: vertical ? 4 : '0px 4px',
    width,
    height,
    userSelect: 'none',
    color: disabled
      ? theme.palette.action.disabled
      : selected
      ? theme.palette.secondary.main
      : theme.palette.primary.contrastText,

    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: disabled
        ? theme.palette.action.disabled
        : button
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText,
      backgroundColor: lighten(theme.palette.primary.main, 0.1)
    },
    display: 'flex',
    alignItems: 'center',
    cursor: button ? 'pointer' : null
  })
)

const Text = styled('div')(({ theme }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  width: '100%'
}))

export const ScrollToolbarTextItemBase = ({
  text,
  button,
  selected,
  mainDimension,
  vertical,
  ...props
}) => {
  const width = vertical ? mainDimension : 'auto'
  const height = vertical ? 'auto' : mainDimension

  return (
    <Root
      width={width}
      height={height}
      selected={selected}
      button={button}
      length={mainDimension}
      vertical={vertical}
      {...props}
    >
      <Text>{text}</Text>
    </Root>
  )
}
export const ScrollToolbarTextItem = ({ ...props }) => {
  const { vertical, mainDimension } = useScrollToolbarContext()
  return (
    <ScrollToolbarTextItemBase
      vertical={vertical}
      mainDimension={mainDimension}
      {...props}
    />
  )
}
export default ScrollToolbarTextItem
