import { Suspense, useLayoutEffect, useState } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'
import useMeasure from 'react-use-measure'
import { styled, ThemeProvider } from '@mui/material/styles'

import { Controls } from './Controls'
import View from './View'

import MainTheme from '../settings/Theme'
import { setRootBoundsAction } from '../modules/mainStore'
import useH3DStore from '../modules/useH3DStore'
import { initAction } from '../modules/modelStore'

const Root = styled('div')(({ theme, height }) => ({
  width: '100vw',
  height: height,
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'start',
  alignItems: 'stretch',
  position: 'relative',
  overflow: 'hidden'
}))

const Main = () => {
  const [height, setHeight] = useState(window.innerHeight)
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const setRootBounds = useH3DStore(setRootBoundsAction)
  const init = useH3DStore(initAction)

  //
  useLayoutEffect(() => {
    init()
  }, [init])

  useLayoutEffect(() => {
    const handleEvent = (e) => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleEvent)
    return () => {
      window.removeEventListener('resize', handleEvent)
    }
  }, [])

  //Tamaño
  useLayoutEffect(() => {
    if (rootBounds) {
      setRootBounds(rootBounds)
    }
  }, [rootBounds, setRootBounds])

  return (
    <ThemeProvider theme={MainTheme}>
      <Suspense>
        <Root ref={rootBoundsRef} height={height}>
          <Controls />
          <View />
        </Root>
      </Suspense>
    </ThemeProvider>
  )
}
export default Main
