import { useMemo, useCallback, cloneElement } from 'react'
import { styled } from '@mui/material/styles'

import { setNodeExpandedAction } from '../modules/propertyTreeStore'
import usePropertyTree from '../modules/usePropertyTree'

import { SPLITTER_TRANSPARENTBAR_WIDTH } from '../constants'
import { getReactNodeChildren } from '@pano/utils'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'transparentBarWidth'
})(({ theme, transparentBarWidth }) => ({
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center',
  paddingLeft: transparentBarWidth,
  paddingRight: transparentBarWidth - 4,
  width: '100%',
  height: '100%',
  overflow: 'hidden'
}))

const PropertyTreeNodeContent = ({
  id,
  expanded,
  readOnly,
  disabled,
  input,
  leftElements,
  rightElements
}) => {
  const setNodeExpanded = usePropertyTree(setNodeExpandedAction)

  const left = useMemo(() => {
    return getReactNodeChildren(leftElements)
  }, [leftElements])

  const right = useMemo(() => {
    return getReactNodeChildren(rightElements)
  }, [rightElements])

  const handleClick = useCallback(() => {
    if (readOnly) {
      setNodeExpanded(id, !expanded)
    }
  }, [id, setNodeExpanded, expanded, readOnly])

  return (
    <Root
      style={{ cursor: readOnly ? 'pointer' : null }}
      transparentBarWidth={SPLITTER_TRANSPARENTBAR_WIDTH}
      onClick={handleClick}
    >
      {left.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            paddingRight: 8
          }}
        >
          {left.map((e, idx) =>
            cloneElement(e, {
              nodeId: id,
              index: { idx },
              disabled
            })
          )}
        </div>
      )}
      {input}
      {right.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            paddingLeft: 8
          }}
        >
          {right.map((e, idx) =>
            cloneElement(e, {
              nodeId: id,
              index: { idx },
              disabled
            })
          )}
        </div>
      )}
    </Root>
  )
}
export default PropertyTreeNodeContent
