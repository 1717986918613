import { useMemo, useEffect, memo, Children, cloneElement } from 'react'
import { styled, ThemeProvider, useTheme } from '@mui/material/styles'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import PropertyTreeHeader from '../Header/PropertyTreeHeader'
import Filler from './Filler'

import {
  addTreeAction,
  setLeftWidthFixedAction,
  setNodeHeightAction,
  setTotalWidthAction
} from '../modules/propertyTreeStore'
import usePropertyTree from '../modules/usePropertyTree'

import { getPropertyTreeTheme } from '../PropertyTreeTheme'

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  flexFlow: 'column',
  boxSizing: 'border-box',
  border: `1px solid ${theme.tree.borderColor}`,
  maxHeight: '100%',
  minHeight: 0
}))

const NodesWrapper = styled('div')(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  display: 'flex',
  flexFlow: 'column',
  ...theme.scrollbar
}))

export const PropertyTree = memo(
  ({
    header = false,
    nodeHeight = 32,
    fixed = false,
    children,
    headerProps,
    ...props
  }) => {
    const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })

    const setWidth = usePropertyTree(setTotalWidthAction)
    const setNodeHeight = usePropertyTree(setNodeHeightAction)
    const setLeftWidthFixed = usePropertyTree(setLeftWidthFixedAction)

    const theme = useTheme()
    const hijos = useMemo(() => {
      return Children.toArray(children).filter((child) => child)
    }, [children])

    useEffect(() => {
      setNodeHeight(nodeHeight)
      setLeftWidthFixed(fixed)
    }, [fixed, nodeHeight, setNodeHeight, setLeftWidthFixed])

    useEffect(() => {
      if (rootBounds?.width > 0) {
        setWidth(rootBounds.width)
      }
    }, [rootBounds, setWidth])

    return (
      <ThemeProvider theme={getPropertyTreeTheme(theme)}>
        <Root ref={rootBoundsRef} {...props}>
          {header && <PropertyTreeHeader {...headerProps} />}
          <NodesWrapper>
            {hijos &&
              hijos.map((item, idx) =>
                cloneElement(item, { id: item.id ? item.id : `n${idx}`, idx })
              )}
            <Filler />
          </NodesWrapper>
        </Root>
      </ThemeProvider>
    )
  }
)
export default PropertyTree
