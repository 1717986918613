export const ICON_IDS = {
  OK: 'OK',
  RESIZE: 'RESIZE',
  ROTATE: 'ROTATE',
  TAKE_SCREENSHOT: 'TAKE_SCREENSHOT',
  IMPORT: 'IMPORT',
  RELOAD: 'RELOAD',
  EXPAND_MENU: 'EXPAND_MENU',
  THREED: 'THREED',
  DIAGRAM: 'DIAGRAM',
  SELECTED: 'SELECTED',
  MOUSE: 'MOUSE',
  SNAPSHOT: 'SNAPSHOT',
  PANO_DIALOG: 'PANO_DIALOG',
  FULL_SCREEN: 'FULL_SCREEN',
  EXIT_FULL_SCREEN: 'EXIT_FULL_SCREEN',
  INFO: 'INFO',
  CIRCLE_NEXT: 'CIRCLE_NEXT',
  CIRCLE_PREV: 'CIRCLE_PREV',
  CIRCLE_PLAY: 'CIRCLE_PLAY',
  CIRCLE_CLOSE: 'CIRCLE_CLOSE',
  GALLERY: 'GALLERY',
  VIEW_UP: 'VIEW_UP',
  VIEW_DOWN: 'VIEW_DOWN',
  VIEW_LEFT: 'VIEW_LEFT',
  VIEW_RIGHT: 'VIEW_RIGHT',
  NEXT_POINT: 'NEXT_POINT',
  PREV_POINT: 'PREV_POINT',
  IMPORT_PREVIOUS: 'IMPORT_PREVIOUS',
  IMPORT_NEXT: 'IMPORT_NEXT',
  NEW: 'NEW',
  OPEN: 'OPEN',
  SAVE: 'SAVE',
  ADD: 'ADD',
  DUPLICATE: 'DUPLICATE',
  EDIT: 'EDIT',
  REMOVE: 'REMOVE',
  SELECT_ALL: 'SELECT_ALL',
  DESELECT_ALL: 'DESELECT_ALL',
  SCENE: 'SCENE',
  POINT: 'POINT',
  HOTSPOT: 'HOTSPOT',
  MEDIA: 'MEDIA',
  LINK: 'LINK',
  SELECTOR: 'SELECTOR',
  MAP_EDITOR: 'MAP_EDITOR',
  TEXT_EDITOR: 'TEXT_EDITOR',
  BUILD: 'BUILD',
  PREVIEW: 'PREVIEW',
  MOVE_UP: 'MOVE_UP',
  GOTO: 'GOTO',
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
  EXPAND: 'EXPAND',
  COLLAPSE: 'COLLAPSE',
  LIST_MENU: 'LIST_MENU',
  ADD_POLYGON_POINT: 'ADD_POLYGON_POINT',
  SETTINGS: 'SETTINGS',
  MAP: 'MAP',
  RADAR: 'RADAR',
  ERROR: 'ERROR',
  CHECK: 'CHECK',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  EQUIRECTANGULAR: 'EQUIRECTANGULAR',
  IMAGE_STRIP: 'IMAGE_STRIP',
  CLOSE: 'CLOSE',
  TREE_EXPAND: 'TREE_EXPAND',
  TREE_COLLAPSE: 'TREE_COLLAPSE',
  POLYGON: 'POLYGON',
  POINTER: 'POINTER',
  SPLIT_LINE: 'SPLIT_LINE',
  REMOVE_POLYGON_POINT: 'REMOVE_POLYGON_POINT',
  FIRST_STEP: 'FIRST_STEP',
  PREV_STEP: 'PREV_STEP',
  NEXT_STEP: 'NEXT_STEP',
  LAST_STEP: 'LAST_STEP',
  RECORD: 'RECORD',
  BOTTOM_RIGHT_RESIZER: 'BOTTOM_RIGHT_RESIZER',
  START_RECORDING: 'START_RECORDING',
  STOP_RECORDING: 'STOP_RECORDING',
  PAUSE_RECORDING: 'PAUSE_RECORDING',
  PLAY_RECORDING: 'PLAY_RECORDING',
  MAXIMIZE: 'MAXIMIZE',
  MINIMIZE: 'MINIMIZE',
  SHOW_BAR: 'SHOW_BAR',
  HIDE_BAR: 'HIDE_BAR',
  MUSIC_ON: 'MUSIC_ON',
  MUSIC_OFF: 'MUSIC_OFF',
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
  HOME: 'HOME',
  AUTOROTATE: 'AUTOROTATE',
  AUDIO: 'MUSIC',
  CAMERA: 'CAMERA',
  POLYGONS: 'POLYGONS',
  MOVE: 'MOVE',
  FIT: 'FIT',
  BLOCKED: 'BLOCKED',
  UNBLOCKED: 'UNBLOCKED',
  DOWN_STEP: 'DOWN_STEP',
  UP_STEP: 'UP_STEP'
}
