import { useCallback } from 'react'
import Select from '../../../Inputs/Select'

import PropertyTreeNode from '../../Node/PropertyTreeNode'

export const PropertyTreeSelectNode = ({
  id,
  value,
  disabled,
  readOnly,
  textAlign,
  inputProps,
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (e) => {
      onChange && onChange(id, e.target.value)
    },
    [onChange, id]
  )

  return (
    <PropertyTreeNode
      id={id}
      disabled={disabled}
      readOnly={readOnly}
      input={
        <Select
          id={id}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          textAlign={textAlign}
          {...inputProps}
        >
          {inputProps?.options}
        </Select>
      }
      {...props}
    />
  )
}
export default PropertyTreeSelectNode
