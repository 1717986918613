import { useState, useCallback, useEffect, useMemo } from 'react'
// import { styled } from '@mui/material/styles'
import Popover from '@mui/material/Popover'
import { SketchPicker } from 'react-color'

import { hex2Rgb, hex2Rgba, rgb2Hex, rgba2Hex } from '@pano/utils'
import BaseTextField from './BaseTextField'

// const STextField = styled(TextField, {
//   shouldForwardProp: (prop) => prop !== 'selected'
// })(({ theme, selected, value }) => ({
//   backgroundColor: value,
//   '& .MuiInput-input': { textAlign: 'center', textTransform: 'uppercase' }
// }))

const ColorField = ({
  id,
  value,
  readOnly,
  disabled,
  disableAlpha,
  validateValue,
  onChange,
  onEditingChange,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { color, hexColor } = useMemo(() => {
    const color = disableAlpha
      ? hex2Rgb(value ? value : '#FFFFFF')
      : hex2Rgba(value ? value : '#FFFFFFFF')

    return {
      color,
      hexColor: disableAlpha ? rgb2Hex(color) : rgba2Hex(color)
    }
  }, [value, disableAlpha])

  // console.log(color, hexColor, '#f000f000')
  useEffect(() => {
    setAnchorEl(null)
  }, [readOnly])

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(disabled ? null : event.currentTarget)
    },
    [disabled]
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleChangeComplete = (newColor) => {
    if (!readOnly && onChange) {
      onChange(
        id,
        disableAlpha
          ? newColor.hex
          : rgba2Hex({
              r: newColor.rgb.r,
              g: newColor.rgb.g,
              b: newColor.rgb.b,
              a: newColor.rgb.a * 255
            })
      )
    }
  }

  return (
    <>
      <BaseTextField
        type={'text'}
        value={hexColor}
        disabled={disabled}
        inputProps={{
          sx: {
            textAlign: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'uppercase',
            backgroundColor: hexColor
          },
          readOnly: true
        }}
        onPointerDown={handleClick}
        {...props}
      />
      {!readOnly && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <SketchPicker
            disableAlpha={disableAlpha}
            color={color}
            onChangeComplete={handleChangeComplete}
          />
        </Popover>
      )}
    </>
  )
}

export default ColorField
