import { useRef } from 'react'
import { PerspectiveCamera } from '@react-three/drei'

import { getCameraCfg } from '../modules/modelStore'
import useH3DStore from '../modules/useH3DStore'

const Camera = ({ ...props }) => {
  const cameraRef = useRef()
  const { position, far } = useH3DStore(getCameraCfg)

  // console.log(position)
  return (
    <PerspectiveCamera
      ref={cameraRef}
      position={position}
      near={20}
      far={far}
      makeDefault
      regress
      {...props}
    />
  )
}

export default Camera
