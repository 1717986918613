const pad = (value) => {
  return value.length < 2 ? '0' + value : value
}
//Valores [0-255]
export const rgb2Hex = (rgb) => {
  return rgb ? rgb2Hex_2(rgb.r, rgb.g, rgb.b) : '#000000'
}

//Valores [0-255]
export const rgb2Hex_2 = (r, g, b) => {
  return (
    '#' +
    pad(r ? parseInt(r).toString(16) : '0') +
    pad(g ? parseInt(g).toString(16) : '0') +
    pad(b ? parseInt(b).toString(16) : '0')
  )
}

//Valores [0-255]
export const rgb2Hex_3 = (r, g, b) => {
  return 65536 * parseInt(r) + 256 * parseInt(g) + parseInt(b)
}

//Valores [0-255]
export const rgba2Hex = (rgba) => {
  return rgba ? rgba2Hex_2(rgba.r, rgba.g, rgba.b, rgba.a) : '#00000000'
}

//Valores [0-255]
export const rgba2Hex_2 = (r, g, b, a) => {
  return (
    '#' +
    pad(r ? parseInt(r).toString(16) : '0') +
    pad(g ? parseInt(g).toString(16) : '0') +
    pad(b ? parseInt(b).toString(16) : '0') +
    pad(a ? parseInt(a).toString(16) : '0')
  )
}
//Valores [0-255] Hex: #000000
export const hex2Rgb = (hex) => {
  return {
    r: parseInt(hex.slice(1, 3), 16),
    g: parseInt(hex.slice(3, 5), 16),
    b: parseInt(hex.slice(5, 7), 16)
  }
}
//Valores [0-1] Hex: #000000
export const hex2Rgb_2 = (hex) => {
  return {
    r: parseInt(hex.slice(1, 3), 16) / 255,
    g: parseInt(hex.slice(3, 5), 16) / 255,
    b: parseInt(hex.slice(5, 7), 16) / 255
  }
}
//Valores [0-255] Hex:#00000000
export const hex2Rgba = (hex) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16),
    a = parseInt(hex.slice(7, 9), 16)
  return rgbaColor(r, g, b, a)
}

export const rgbaColor = (r = 0, g = 0, b = 0, a = 0) => {
  return { r: r, g: g, b: b, a: a }
}

export const toCSSColor = (color, alpha) =>
  `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`

// export const similarColors = (color0, color1) => {
//   return color0.r === color1.r && color0.g === color1.g && color0.b === color1.b
// }

export const similarColors = (color0, color1, delta) => {
  return (
    color0.r >= color1.r - delta &&
    color0.r <= color1.r + delta &&
    color0.g >= color1.g - delta &&
    color0.g <= color1.g + delta &&
    color0.b >= color1.b - delta &&
    color0.b <= color1.b + delta
  )
}

export const isRgbBlack = (color) => {
  return color.r === 0 && color.b === 0 && color.b === 0
}

export const isRgbWhite = (color) => {
  return color.r === 255 && color.b === 255 && color.b === 255
}

export const getRgbaColorFromArray = (index, data) => {
  return {
    r: data[index],
    g: data[index + 1],
    b: data[index + 2],
    a: data[index + 3]
  }
}

export const inverRgbaColor = (color) => {
  return { r: 255 - color.r, g: 255 - color.g, b: 255 - color.b, a: color.a }
}
/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSL representation
 */
export const rgbToHsl = (r, g, b) => {
  r /= 255
  g /= 255
  b /= 255

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  var h,
    s,
    l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    var d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
      default:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  return [h, s, l]
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  l       The lightness
 * @return  Array           The RGB representation
 */
export const hslToRgb = (h, s, l) => {
  var r, g, b

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s
    var p = 2 * l - q

    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return [r * 255, g * 255, b * 255]
}

/**
 * Converts an RGB color value to HSV. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and v in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSV representation
 */
export const rgbToHsv = (r, g, b) => {
  r /= 255
  g /= 255
  b /= 255

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  var h,
    s,
    v = max

  var d = max - min
  s = max === 0 ? 0 : d / max

  if (max === min) {
    h = 0 // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
      default:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return [h, s, v]
}

/**
 * Converts an HSV color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes h, s, and v are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  v       The value
 * @return  Array           The RGB representation
 */
export const hsvToRgb = (h, s, v) => {
  var r, g, b

  var i = Math.floor(h * 6)
  var f = h * 6 - i
  var p = v * (1 - s)
  var q = v * (1 - f * s)
  var t = v * (1 - (1 - f) * s)

  switch (i % 6) {
    case 0:
      r = v
      g = t
      b = p
      break
    case 1:
      r = q
      g = v
      b = p
      break
    case 2:
      r = p
      g = v
      b = t
      break
    case 3:
      r = p
      g = q
      b = v
      break
    case 4:
      r = t
      g = p
      b = v
      break
    case 5:
    default:
      r = v
      g = p
      b = q
      break
  }

  return [r * 255, g * 255, b * 255]
}
