import { useState, useCallback, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useSpring, animated, config } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { invalidate } from '@react-three/fiber'

import ScrollButton from './ScrollButton'
import { ScrollToolbarContextProvider } from './ScrollToolbarContext'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'vertical' && prop !== 'mainDimension'
})(({ theme, mainDimension, vertical }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.light,
  boxSizing: 'borer-box',
  minHeight: vertical ? 0 : mainDimension,
  height: vertical ? '100%' : mainDimension,

  minWidth: vertical ? mainDimension : 0,
  width: vertical ? mainDimension : '100%',

  display: 'flex',
  flexFlow: vertical ? 'column' : 'row'
}))

const DraggingMask = styled('div')(({ theme }) => ({
  backgroundColor: '#00000040',
  position: 'absolute',
  zIndex: 1000,
  width: '100%',
  height: '100%'
}))

const ItemsWindow = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative'
}))

const ItemsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'vertical' && prop !== 'minLength'
})(({ theme, vertical, minLength }) => ({
  position: 'absolute',
  width: vertical ? '100%' : null,
  minWidth: vertical ? null : minLength,
  height: vertical ? null : '100%',
  minHeight: vertical ? minLength : null,
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: vertical ? 'column' : 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  touchAction: 'none'
}))

export const ScrollToolbar = ({
  vertical,
  mainDimension,
  children,
  ...props
}) => {
  const timerId = useRef(null)
  const [toolbarBoundsRef, toolbarBounds] = useMeasure({
    polyfill: ResizeObserver
  })
  const [itemsBoundsRef, itemsBounds] = useMeasure({
    polyfill: ResizeObserver
  })

  const [offset, setOffset] = useState(0)
  const [arrows, setArrows] = useState(false)
  const [draggingOffset, setDraggingOffset] = useState(0)
  const toolbarLength = toolbarBounds
    ? vertical
      ? toolbarBounds.height
      : toolbarBounds.width
    : 0
  const itemsLength = itemsBounds
    ? vertical
      ? itemsBounds.height
      : itemsBounds.width
    : 0

  const trucanteOffset = (toolbarLength, itemsLength, offset) => {
    return Math.min(0, Math.max(toolbarLength - itemsLength, offset))
  }

  const getOffset = useCallback(
    (inc) => {
      return trucanteOffset(toolbarLength, itemsLength, offset + inc)
    },
    [offset, toolbarLength, itemsLength]
  )

  useEffect(() => {
    if (itemsLength) {
      if (timerId.current) {
        clearTimeout(timerId.current)
      }
      timerId.current = setTimeout(() => {
        setOffset(getOffset(0))
        setArrows(toolbarLength < itemsLength)
      }, 500)
    }
  }, [toolbarLength, vertical, itemsLength, getOffset])

  const handleNext = useCallback(() => {
    setOffset(getOffset((-toolbarLength * 2) / 3))
  }, [getOffset, toolbarLength])

  const handlePrev = useCallback(() => {
    setOffset(getOffset((toolbarLength * 2) / 3))
  }, [toolbarLength, getOffset])

  const leftAnimation = useSpring({
    left: vertical
      ? null
      : trucanteOffset(toolbarLength, itemsLength, offset + draggingOffset),
    top: vertical
      ? trucanteOffset(toolbarLength, itemsLength, offset + draggingOffset)
      : null,
    config: config.default,
    onProps: () => {
      invalidate()
    },
    onChange: () => {
      invalidate()
    }
  })

  const bind = useGesture({
    onDrag: ({ movement }) => {
      setDraggingOffset(vertical ? movement[1] : movement[0])
    },
    onDragEnd: () => {
      setDraggingOffset(0)
      setOffset(
        trucanteOffset(toolbarLength, itemsLength, offset + draggingOffset)
      )
    },
    onWheel: (state) => {
      console.log(state.direction)
      setOffset(
        trucanteOffset(
          toolbarLength,
          itemsLength,
          offset - state.direction[1] * mainDimension
        )
      )
    }
  })

  const AnimatedItemsWrapper = animated(ItemsWrapper)
  return (
    <ScrollToolbarContextProvider
      vertical={vertical}
      mainDimension={mainDimension}
    >
      <Root vertical={vertical} mainDimension={mainDimension} {...props}>
        {draggingOffset !== 0 && arrows && <DraggingMask />}
        {arrows && (
          <ScrollButton
            itemsLength={itemsLength}
            toolbarLength={
              vertical ? toolbarBounds.height : toolbarBounds.width
            }
            onClick={handlePrev}
            offset={offset}
            vertical={vertical}
          />
        )}
        <ItemsWindow ref={toolbarBoundsRef}>
          <AnimatedItemsWrapper
            ref={itemsBoundsRef}
            {...bind()}
            style={leftAnimation}
            vertical={vertical}
            minLength={toolbarLength}
          >
            {children}
          </AnimatedItemsWrapper>
        </ItemsWindow>
        {arrows && (
          <ScrollButton
            itemsLength={itemsLength}
            toolbarLength={
              vertical ? toolbarBounds.height : toolbarBounds.width
            }
            onClick={handleNext}
            offset={offset}
            next
            vertical={vertical}
          />
        )}
      </Root>
    </ScrollToolbarContextProvider>
  )
}
export default ScrollToolbar
