import create from 'zustand'
import { devtools } from 'zustand/middleware'

// import lightStore from './lightStore'
import mainStore from './mainStore'
import modelStore from './modelStore'

export const useH3DStore = create(
  devtools(
    (set, get) => ({
      ...mainStore(set, get),
      ...modelStore(set, get)

      //
      // ...lightStore(set, get)
    }),
    { name: 'Houses 3D store' }
  )
)
export default useH3DStore
