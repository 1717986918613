import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

import PropertyTreeRow from '../Tree/PropertyTreeRow'
import Splitter from '../Splitter/Splitter'
import {
  HEADER_DEFAULT_HEIGHT,
  SPLITTER_TRANSPARENTBAR_WIDTH
} from '../constants'
import { leftWidthSelector } from '../modules/propertyTreeStore'
import usePropertyTree from '../modules/usePropertyTree'

const Root = styled(PropertyTreeRow, {
  shouldForwardProp: (prop) => prop !== 'height'
})(({ theme, height }) => ({
  display: 'flex',
  flexFlow: 'row',
  boxSizing: 'border-box',
  width: '100%',
  height: height,
  backgroundColor: theme.palette.primary.main
}))

const Left = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'width' && prop !== 'transparentBarWidth'
})(({ theme, width, transparentBarWidth }) => ({
  boxSizing: 'border-box',
  width,
  height: '100%',
  minWidth: width,
  padding: '0.5rem',
  paddingRight: transparentBarWidth,
  display: 'flex',
  alignItems: 'center'
}))
const Text = styled('div')(({ theme }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  userSelect: 'none'
}))

const Right = styled('div', {
  shouldForwardProp: (prop) => prop !== 'transparentBarWidth'
})(({ theme, transparentBarWidth }) => ({
  boxSizing: 'border-box',
  padding: '0.5rem',
  paddingLeft: transparentBarWidth,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minWidth: 0
}))

export const PropertyTreeHeader = ({
  leftLabel = 'Property',
  rightLabel = 'Value',
  height = HEADER_DEFAULT_HEIGHT,
  onLeftClick,
  onRightClick,
  ...props
}) => {
  const leftWidth = usePropertyTree(leftWidthSelector)
  return (
    <Root height={height} {...props}>
      <Left
        width={leftWidth}
        transparentBarWidth={SPLITTER_TRANSPARENTBAR_WIDTH}
        onClick={onLeftClick}
      >
        <Text>{leftLabel}</Text>
      </Left>
      <Splitter />
      <Right
        transparentBarWidth={SPLITTER_TRANSPARENTBAR_WIDTH}
        onClick={onRightClick}
      >
        <Text>{rightLabel}</Text>
      </Right>
    </Root>
  )
}

PropertyTreeHeader.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  height: PropTypes.number
}

export default PropertyTreeHeader
