import { useState } from 'react'
import { styled } from '@mui/material/styles'
import MaterialSelect from '@mui/material/Select'

const Root = styled(MaterialSelect)(({ theme }) => ({
  // '& .MuiMenu-paper': {
  //   color: theme.palette.primary.contrastText,
  //   backgroundColor: lighten(theme.palette.primary.light, 0.05),
  //   borderRadius: 0,
  //   border: 'none',
  //   boxSizing: 'border-box',
  //   minWidth: 160,
  //   maxWidth: 460,
  //   padding: 0,
  //   ...theme.scrollbar
  // },
  // '& .MuiMenu-list': { padding: 0 }
}))

export const Select = ({ textAlign, children, ...props }) => {
  const [open, setOpen] = useState(false)
  const handleClose = (e) => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Root
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      inputProps={{
        sx: {
          textAlign
        }
      }}
      {...props}
    >
      {children}
    </Root>
  )
}

export default Select
