import { styled, lighten } from '@mui/material/styles'
import MaterialMenu from '@mui/material/Menu'
import { Children, cloneElement, useMemo } from 'react'

const Root = styled(MaterialMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    color: theme.palette.primary.contrastText,
    backgroundColor: lighten(theme.palette.primary.light, 0.05),
    borderRadius: 0,
    border: 'none',
    boxSizing: 'border-box',
    minWidth: 160,
    maxWidth: 460,
    padding: 0,
    ...theme.scrollbar
  },
  '& .MuiMenu-list': { padding: 0 }
}))

export const Menu = ({ anchor, onClose, children, ...props }) => {
  const open = Boolean(anchor)
  const hijos = useMemo(() => {
    return Children.toArray(children).filter((child) => child)
  }, [children])

  return (
    <Root anchorEl={anchor} open={open} onClose={onClose} {...props}>
      {hijos &&
        hijos.map((item, idx) => {
          return item
            ? cloneElement(item, {
                key: idx,
                onCloseParentMenu: onClose
              })
            : null
        })}
    </Root>
  )
}

export default Menu
