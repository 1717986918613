import SvgIcon from '@mui/material/SvgIcon'
function BottomRightResizerIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M23.062 23.258h-4.391v-4.467h4.391v4.467m0-8.934h-4.391V9.857h4.391v4.467m-8.782 8.934H9.889v-4.467h4.39v4.467m0-8.934H9.89V9.857h4.39v4.467m-8.781 8.934H1.106v-4.467h4.392v4.467M23.062 5.39h-4.391V.924h4.391z"
        style={{
          strokeWidth: 0.0519007
        }}
      />
    </SvgIcon>
  )
}
export default BottomRightResizerIcon
