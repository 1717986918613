import {
  useCallback,
  useMemo,
  useRef,
  useState,
  Children,
  cloneElement
} from 'react'
import { styled, lighten } from '@mui/material/styles'
import MaterialMenuItem from '@mui/material/MenuItem'
import { Typography } from '@mui/material'
import { useGesture } from '@use-gesture/react'

import Icon from '../Icons/Icons'
import { ICON_IDS } from '../Icons/constants'
import Menu from './Menu'

const Root = styled(MaterialMenuItem)(({ theme }) => ({
  touchAction: 'none',
  paddingRight: 0,
  width: '100%',

  fontSize: 14,
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    backgroundColor: lighten(theme.palette.primary.light, 0.1)
  },
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.light, 0.1),
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.primary.light, 0.1)
    }
  },
  '&.MuiMenuItem-divider': {
    borderColor: theme.palette.primary.contrastText
  }
}))
const Text = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: 12
}))
const ItemIcon = styled(Icon)(({ theme }) => ({
  fontSize: 12,
  minWidth: 0,
  padding: '0 12px 0 0'
}))
const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}))

export const MenuItemButton = ({ iconId, onClick }) => {
  return <ItemIcon id={iconId} onClick={onClick} />
}
export const MenuItem = ({
  id,
  label,
  closeOnClick, //Cierra menu al que pertenece
  closeParentMenu, //Cierra menu anterior
  onCloseParentMenu, //Se rellena solo
  onClick,
  children,
  buttons,
  hoverButtons,
  menuProps,
  ...props
}) => {
  const ref = useRef(null)
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const hijos = useMemo(() => {
    return Children.toArray(children).filter((child) => child)
  }, [children])
  const hasChildren = hijos.length > 0

  const [hover, setHover] = useState(false)

  const bind = useGesture({
    onHover: ({ hovering }) => {
      setHover(hovering)
    }
  })

  const handleClose = useCallback(
    (e) => {
      setAnchor(null)
      closeParentMenu && onCloseParentMenu(e)
      e.stopPropagation()
    },
    [closeParentMenu, onCloseParentMenu]
  )

  const handleClick = useCallback(
    (e) => {
      onClick && onClick(e, id)
      if (hasChildren) {
        if (open) {
          handleClose(e)
          e.stopPropagation()
        } else {
          setAnchor(ref.current)
          e.stopPropagation()
        }
      } else {
        e.stopPropagation()
        onCloseParentMenu && closeOnClick && onCloseParentMenu(e)
      }
    },
    [
      onClick,
      id,
      closeOnClick,
      hasChildren,
      onCloseParentMenu,
      handleClose,
      open
    ]
  )

  return (
    <Root
      {...bind()}
      ref={ref}
      onClick={handleClick}
      selected={open}
      dense
      {...props}
    >
      <Text variant="inherit" noWrap>
        {label}
      </Text>
      <IconWrapper>
        {hover && hoverButtons}
        {buttons}
      </IconWrapper>
      {hasChildren && (
        <>
          <MenuItemButton iconId={ICON_IDS.EXPAND_MENU} />
          <Menu
            anchor={anchor}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            {...menuProps}
          >
            {hijos &&
              hijos.map((item, idx) => {
                return item
                  ? cloneElement(item, {
                      key: idx,
                      onCloseParentMenu: handleClose
                    })
                  : null
              })}
          </Menu>
        </>
      )}
    </Root>
  )
}

export default MenuItem
