import { selectItem, clamp } from '@pano/utils'
import { createSelector } from 'reselect'

export const propertyTreeStore = (set, get) => ({
  propertyTree: {
    created: [],
    expanded: [],
    totalWidth: 0,
    leftWidth: 0,
    fixed: false,
    nodeHeight: 32,

    setTotalWidth: (width) => {
      set((st) => ({
        propertyTree: {
          ...st.propertyTree,
          leftWidth: updateLeftWidth(width, leftWidthSelector(get())),
          totalWidth: width
        }
      }))
    },
    setLeftWidth: (width) => {
      set((st) => ({
        propertyTree: {
          ...st.propertyTree,
          leftWidth: updateLeftWidth(totalWidthSelector(get()), width)
        }
      }))
    },
    setLeftWidthFixed: (fixed) => {
      set((st) => ({ propertyTree: { ...st.propertyTree, fixed } }))
    },
    setNodeHeight: (height) => {
      set((st) => ({
        propertyTree: { ...st.propertyTree, nodeHeight: clamp(height, 12, 200) }
      }))
    },
    nodeCreatedAction: (nodeId) => {
      updateNodeState(set, nodeId, 'created', true)
    },
    setNodeExpanded: (nodeId, value) => {
      updateNodeState(set, nodeId, 'expanded', value)
    },
    setExpandedNodes: (ids) => {
      set((st) => ({ propertyTree: { ...st.propertyTree, expanded: ids } }))
    }
  }
})

const updateNodeState = (set, nodeId, propertyName, value) => {
  set((st) => ({
    propertyTree: {
      ...st.propertyTree,
      [propertyName]: selectItem(st.propertyTree[propertyName], nodeId, value)
    }
  }))
}
const updateLeftWidth = (treeWidth, leftWidth) => {
  if (treeWidth < 200) {
    return treeWidth / 2
  } else {
    return clamp(leftWidth, 100, treeWidth - 100)
  }
}

export const nodeCreatedAction = (state) => state.propertyTree.nodeCreatedAction
export const setTotalWidthAction = (state) => state.propertyTree.setTotalWidth
export const setLeftWidthAction = (state) => state.propertyTree.setLeftWidth
export const setNodeHeightAction = (state) => state.propertyTree.setNodeHeight
export const setLeftWidthFixedAction = (state) =>
  state.propertyTree.setLeftWidthFixed

export const setNodeExpandedAction = (state) =>
  state.propertyTree.setNodeExpanded
export const setExpandedNodesAction = (state) =>
  state.propertyTree.setExpandedNodes

export const setNodeDisabledAction = (state) =>
  state.propertyTree.setNodeDisabled
export const setDisabledNodesAction = (state) =>
  state.propertyTree.setDisabledNodes

export const createdSelector = (state) => state.propertyTree.created
export const expandedSelector = (state) => state.propertyTree.expanded
export const fixedSelector = (state) => state.propertyTree.fixed
export const nodeHeightSelector = (state) => state.propertyTree.nodeHeight
export const leftWidthSelector = (state) => state.propertyTree.leftWidth
export const totalWidthSelector = (state) => state.propertyTree.totalWidth

export const getNodeDataMemo = () =>
  createSelector(
    [createdSelector, expandedSelector, nodeHeightSelector, (_, id) => id],
    (created, expanded, height, id) => {
      return {
        height,
        created: created.includes(id),
        expanded: expanded.includes(id)
      }
    }
  )
