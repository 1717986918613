import { createSelector } from 'reselect'

export const MAIN_MODE = {
  READING_DATA: 'READING_DATA',
  LOADING_MODEL: 'LOADING_MODEL',
  VIEW_3D: 'VIEW_3D',
  PLAN: 'PLAN',
  PANO: 'PANO'
}
export const mainStore = (set, get) => ({
  main: {
    bounds: null,
    fullscreen: false,
    mode: MAIN_MODE.READING_DATA,
    filterActive: false,
    background: true,
    setBounds: (bounds) => {
      set((prev) => ({ main: { ...prev.main, bounds } }))
    },
    setFullscreen: (fullscreen) => {
      set((prev) => ({ main: { ...prev.main, fullscreen } }))
    },
    setMode: (mode) => {
      set((prev) => ({ main: { ...prev.main, mode } }))
    },
    setFilterActive: (active) => {
      set((prev) => ({ main: { ...prev.main, filterActive: active } }))
    },
    setBackgroundActive: (active) => {
      set((prev) => ({ main: { ...prev.main, background: active } }))
    }
  }
})
export default mainStore

export const setRootBoundsAction = (state) => state.main.setBounds
export const setFullscreenAction = (state) => state.main.setFullscreen
export const setMainModeAction = (state) => state.main.setMode
export const setFilterActiveAction = (state) => state.main.setFilterActive
export const setBackgroundActiveAction = (state) =>
  state.main.setBackgroundActive

export const editorModeSelector = (state) => state.main.boundsSelector
export const fullscreenSelector = (state) => state.main.fullscreen
export const mainModeSelector = (state) => state.main.mode
export const filterActiveSelector = (state) => state.main.filterActive
export const backgroundActiveSelector = (state) => state.main.background
