import { memo } from 'react'
import { styled } from '@mui/material/styles'

import PropertyTreeNode from '../Node/PropertyTreeNode'
import BaseTextField from '../../Inputs/BaseTextField'
import TextContent from './TextContent'

const ReadOnly = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'textAlign'
})(({ theme, disabled, textAlign }) => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign,
  width: '100%',
  color: disabled ? theme.palette.text.disabled : null,
  userSelect: 'none'
}))

const PropertyTreeTextNode = memo(
  ({
    id,
    readOnly,
    disabled,
    value,
    textAlign,
    onClick,
    onChange,
    inputProps,
    ...props
  }) => {
    return (
      <PropertyTreeNode
        id={id}
        readOnly={readOnly}
        disabled={disabled}
        {...props}
        input={
          readOnly ? (
            <TextContent
              disabled={disabled}
              readOnly={readOnly}
              textAlign={textAlign}
              value={value}
              {...inputProps}
            />
          ) : (
            <BaseTextField
              id={id}
              readOnly={readOnly}
              disabled={disabled}
              value={value}
              textAlign={textAlign}
              onChange={onChange}
              {...inputProps}
            />
          )
        }
      />
    )
  }
)
export default PropertyTreeTextNode
