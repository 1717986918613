import { useCallback, useState } from 'react'
import { styled, lighten } from '@mui/material/styles'
import MaterialMenuItem from '@mui/material/MenuItem'
import { Typography } from '@mui/material'
import { useGesture } from '@use-gesture/react'

import Icon from '../Icons/Icons'

const Root = styled(MaterialMenuItem)(({ theme }) => ({
  touchAction: 'none',
  paddingRight: 0,
  width: '100%',

  fontSize: 14,
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    backgroundColor: lighten(theme.palette.primary.light, 0.1)
  },
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.light, 0.1),
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.primary.light, 0.1)
    }
  },
  '&.MuiMenuItem-divider': {
    borderColor: theme.palette.primary.contrastText
  }
}))
const Text = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: 12
}))
const ItemIcon = styled(Icon)(({ theme }) => ({
  fontSize: 12,
  minWidth: 0,
  padding: '0 12px 0 0'
}))
const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center'
}))

export const SelectItem = ({
  id,
  onClick,
  children,
  buttons,
  hoverButtons,
  ...props
}) => {
  const [hover, setHover] = useState(false)

  const bind = useGesture({
    onHover: ({ hovering }) => {
      setHover(hovering)
    }
  })

  const handleClick = useCallback(
    (e) => {
      onClick && onClick(e, id)
    },
    [onClick, id]
  )

  return (
    <Root {...bind()} onClick={handleClick} dense {...props}>
      <Text variant="inherit" noWrap>
        {children}
      </Text>
      <IconWrapper>
        {hover && hoverButtons}
        {buttons}
      </IconWrapper>
    </Root>
  )
}

export default SelectItem
