import { styled } from '@mui/material/styles'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useScrollToolbarContext } from './ScrollToolbarContext'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'vertical'
})(({ theme, vertical, disabled }) => ({
  height: vertical ? null : '100%',
  minWidth: 0,
  width: vertical ? '100%' : null,
  minHeight: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: disabled ? null : 'pointer',

  backgroundColor: theme.palette.primary.light,
  color: disabled
    ? theme.palette.action.disabled
    : theme.palette.primary.contrastText,
  '&:hover': {
    color: disabled
      ? theme.palette.action.disabled
      : theme.palette.secondary.main
  }
}))

const ScrollButton = ({
  vertical,
  next,
  itemsLength,
  toolbarLength,
  offset,
  onClick
}) => {
  return (
    <Root
      vertical={vertical}
      disabled={next ? offset <= toolbarLength - itemsLength : offset >= 0}
      variant="contained"
      onClick={onClick}
    >
      {next ? (
        <NavigateNextIcon
          sx={{ transform: vertical ? 'rotate(90deg)' : 'none' }}
        />
      ) : (
        <NavigateBeforeIcon
          sx={{ transform: vertical ? 'rotate(90deg)' : 'none' }}
        />
      )}
    </Root>
  )
}

export default ScrollButton
