export const findById = (data, id) => {
  return data ? data.find((d) => d.id === id) : null
}

export const findByProperty = (data, value, property) => {
  return data ? data.find((d) => d[property] === value) : null
}

export const selectItem = (itemsIdArray, itemId, select) => {
  let newItemsIdArray = [...itemsIdArray]
  if (select) {
    if (newItemsIdArray.indexOf(itemId) < 0) {
      newItemsIdArray.push(itemId)
    }
  } else {
    newItemsIdArray = newItemsIdArray.filter((id) => id !== itemId)
  }
  return newItemsIdArray
}

/*
  objectList: Lista objetos
  nameProperty: Nombre de la propiedad que guarda el nombre
  name: Cadena base desde la  que crear el nuevo nombre
*/
export const getFirstNotUsedName = (
  objectList,
  nameProperty,
  name,
  separator = '_'
) => {
  const nameUsed = (tags, name) => {
    return objectList.findIndex((o) => o[nameProperty] === name) >= 0
  }
  let idx = 0
  let newName = `${name}${separator}${idx}`
  while (nameUsed(objectList, newName)) {
    idx++
    newName = `${name}${separator}${idx}`
  }
  return newName
}

export const getLastNotUsedNameInList = (list, name, separator = '_') => {
  const regExp = new RegExp(`^${name}${separator}[0-9]+$`)

  const max = list.reduce((acc, item) => {
    const a = item.match(regExp)

    if (a && a[0]) {
      const number = parseInt(a[0].slice(name.length + separator.length))
      if (number > acc) {
        acc = number
      }
      return acc
    }
    return acc
  }, 0)

  return { name: `${name}${separator}${max + 1}`, number: max + 1 }
}

export const getLastNotUsedName = (
  objectList,
  nameProperty,
  name,
  separator = '_'
) => {
  return getLastNotUsedNameInList(
    objectList ? objectList.map((object) => object[nameProperty]) : [],
    name,
    separator
  )
}

export const swap = (array, fromIndex, toIndex) => {
  const item = array[fromIndex]
  const length = array.length
  const diff = fromIndex - toIndex
  if (
    !array ||
    fromIndex < 0 ||
    toIndex < 0 ||
    fromIndex === toIndex ||
    fromIndex >= array.length ||
    toIndex >= array.length
  ) {
    return array
  }
  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, fromIndex),
      ...array.slice(fromIndex + 1, length)
    ]
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1
    return [
      ...array.slice(0, fromIndex),
      ...array.slice(fromIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length)
    ]
  }
  return array
}

export const clamp = (value, min, max) => {
  return Math.min(Math.max(min, value), max)
}

export const normalizeAngle = (value, start, end) => {
  const width = end - start //
  const offsetValue = value - start

  return offsetValue - Math.floor(offsetValue / width) * width + start
}

export const formatNumber = (num) => {
  return new Intl.NumberFormat('es-ES').format(num)
  // return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const arraysEquals = (a0, a1) => {
  if (a0.length !== a1.length) {
    return false
  }
  for (let i = 0; i < a0.length; i++) {
    if (a0[i] !== a1[i]) {
      return false
    }
  }
  return true
}

export const floatArraysEquals = (a0, a1, delta = 0.001) => {
  if (a0.length !== a1.length) {
    return false
  }
  for (let i = 0; i < a0.length; i++) {
    if (!compareFloatNum(a0[i], a1[i], delta)) {
      return false
    }
  }
  return true
}

export const stringArraysEqualsWithoutOrder = (a0, a1) => {
  if (!a0 || !a1 || a0.length !== a1.length) {
    return false
  }
  if (a0.sort().join(',') === a1.sort().join(',')) {
    return true
  } else {
    return false
  }
}

export const cloneObject = (object) => {
  return object !== null ? JSON.parse(JSON.stringify(object)) : null
}

export const getDefaultObjectFromSchema = (schema) => {
  return schema.default !== null ? cloneObject(schema.default) : null
}

export const uniquesValues = (objectList, propertyName) => {
  if (!objectList || !propertyName) {
    return false
  }
  const set = new Set()
  objectList.forEach((object) => set.add(object[propertyName]))
  return set.size === objectList.length
}

export const repeatedValues = (objectList, propertyName) => {
  if (!objectList || !propertyName) {
    return []
  }
  const values = objectList.map((o) => o[propertyName])
  const uniqueElements = new Set(values)

  const duplicates = values.filter((value) => {
    if (uniqueElements.has(value)) {
      uniqueElements.delete(value)
      return false
    } else {
      return true
    }
  })

  return [...duplicates]
}

export const findAllOcurrences = (objectList, propertyName, value) => {
  if (!objectList || !propertyName) {
    return []
  }
  return objectList.reduce((acc, item, idx) => {
    if (item[propertyName] === value) {
      acc.push(idx)
    }
    return acc
  }, [])
}

export const isValidUrl = (url) => {
  const re =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
  return url?.match(re)
}

export const rad2deg = (rad) => rad * (180 / Math.PI)
export const deg2rad = (deg) => deg * (Math.PI / 180)
export const polar2cartesian_2D = (radius, angleRad) => {
  return {
    x: radius * Math.cos(angleRad),
    y: radius * Math.sin(angleRad)
  }
}

export const arrayHasUniquesValues = (array) => {
  const set = new Set(array)
  return set.size === array.length
}

export const objectArrayHasPropValues = (array, propName, propValues) => {
  return array
    ? propValues.reduce((acc, value) => {
        if (!array.find((item) => item[propName] === value)) {
          acc.push(value)
        }
        return acc
      }, [])
    : propValues
}

export const stringArrayEquals = (a0, a1) => {
  if (!a0 || !a1 || a0.length !== a1.length) {
    return false
  }
  const s0 = a0.sort()
  const s1 = a1.sort()
  return s0.join('') === s1.join('')
}

// Function to return the minimum distance between a line segment AB and a point E
export const minDistance = (A, B, E) => {
  // vector AB
  const AB = []
  AB.push(B[0] - A[0])
  AB.push(B[1] - A[1])

  // vector BP
  const BE = []
  BE.push(E[0] - B[0])
  BE.push(E[1] - B[1])

  // vector AP
  const AE = []
  AE.push(E[0] - A[0])
  AE.push(E[1] - A[1])

  // constiables to store dot product
  let AB_BE, AB_AE

  // Calculating the dot product
  AB_BE = AB[0] * BE[0] + AB[1] * BE[1]
  AB_AE = AB[0] * AE[0] + AB[1] * AE[1]

  // Minimum distance from point E to the line segment
  let reqAns = 0
  // Case 1
  if (AB_BE > 0) {
    // Finding the magnitude
    const y = E[1] - B[1]
    const x = E[0] - B[0]
    reqAns = Math.sqrt(x * x + y * y)
  }
  // Case 2
  else if (AB_AE < 0) {
    const y = E[1] - A[1]
    const x = E[0] - A[0]
    reqAns = Math.sqrt(x * x + y * y)
  }
  // Case 3
  else {
    // Finding the perpendicular distance
    const x1 = AB[0]
    const y1 = AB[1]
    const x2 = AE[0]
    const y2 = AE[1]
    const mod = Math.sqrt(x1 * x1 + y1 * y1)
    reqAns = Math.abs(x1 * y2 - y1 * x2) / mod
  }
  return reqAns
}

export const compareFloatNum = (a, b, delta = 1e-3) => {
  return Math.abs(a - b) < delta
}

export const areObjectDeepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !areObjectDeepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }
  return true
}

export const isObject = (object) => {
  return object != null && typeof object === 'object'
}

export const getFileExt = (path) => {
  if (!path) {
    return ''
  }
  const fields = path.split('.')
  return fields[fields.length - 1]
}

export const getLines = (str) => {
  return str ? str.split(/\r?\n/).filter((element) => element) : []
}

export const getRotatedRectangleBoundingBox = (x, y, w, h, angleRad) => {
  const absCosRA = Math.abs(Math.cos(angleRad))
  const absSinRA = Math.abs(Math.sin(angleRad))

  const bbW = w * absCosRA + h * absSinRA
  const bbH = w * absSinRA + h * absCosRA

  const bbX = x - (bbW - w) / 2
  const bbY = y - (bbH - h) / 2

  return { x: bbX, y: bbY, width: bbW, height: bbH }
}

export const loadImagePromise = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.onerror = img.onabort = () => {
      reject(src)
    }
    img.src = src
  })
}
