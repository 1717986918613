import { createContext, useContext } from 'react'
import { createStore, useStore } from 'zustand'
import { devtools } from 'zustand/middleware'
import { propertyTreeStore } from './propertyTreeStore'

const PropertyTreeContext = createContext()
const propertyTreeStores = []

export const PropertyTreeProvider = ({ id, children }) => {
  let store = propertyTreeStores.find((store) => store.id === id)
  if (!store) {
    store = {
      id,
      store: createStore(
        devtools(
          (set, get) => ({
            ...propertyTreeStore(set, get, id)
          }),
          { name: `PropertyTree_${id}` }
        )
      )
    }
    propertyTreeStores.push(store)
  }

  return (
    <PropertyTreeContext.Provider value={store}>
      {children}
    </PropertyTreeContext.Provider>
  )
}

export const usePropertyTree = (selector, equalityFn) => {
  const { store } = useContext(PropertyTreeContext)
  return useStore(store, selector, equalityFn)
}

export default usePropertyTree
