import SaveIcon from '@mui/icons-material/Save'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import SettingsIcon from '@mui/icons-material/Settings'
import ErrorIcon from '@mui/icons-material/Error'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import PlaceIcon from '@mui/icons-material/Place'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import TocIcon from '@mui/icons-material/Toc'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import VideocamIcon from '@mui/icons-material/Videocam'
import ImageIcon from '@mui/icons-material/Image'
import PanoramaPhotosphereIcon from '@mui/icons-material/PanoramaPhotosphere'
import Crop75Icon from '@mui/icons-material/Crop75'
import BuildIcon from '@mui/icons-material/Build'
import CheckIcon from '@mui/icons-material/Check'
import PreviewIcon from '@mui/icons-material/Preview'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuIcon from '@mui/icons-material/Menu'
import MapIcon from '@mui/icons-material/Map'
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import LastPageIcon from '@mui/icons-material/LastPage'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import StopIcon from '@mui/icons-material/Stop'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import WebAssetIcon from '@mui/icons-material/WebAsset'
import MinimizeIcon from '@mui/icons-material/Minimize'
import DataObjectIcon from '@mui/icons-material/DataObject'
import HomeIcon from '@mui/icons-material/Home'
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty'
import EjectIcon from '@mui/icons-material/Eject'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import MusicOffIcon from '@mui/icons-material/MusicOff'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import CollectionsIcon from '@mui/icons-material/Collections'
import InfoIcon from '@mui/icons-material/Info'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'
import MouseIcon from '@mui/icons-material/Mouse'
import PanToolIcon from '@mui/icons-material/PanTool'
import SchemaIcon from '@mui/icons-material/Schema'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CachedIcon from '@mui/icons-material/Cached'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import CameraIcon from '@mui/icons-material/Camera'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import HeightIcon from '@mui/icons-material/Height'
import FitScreenIcon from '@mui/icons-material/FitScreen'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

// import { ICON_IDS } from '../../../settings/constants'
// import PointIcon from './PointIcon'
// import PolygonIcon from './PolygonIcon'
// import PointerIcon from './PointerIcon'
// import SplitLineIcon from './SplitLineIcon'
import BottomRightResizerIcon from './BottomRightResizerIcon'
import { ICON_IDS } from './constants'
// import TriangleIcon from './TriangleIcon'
// import CircleCloseIcon from './CircleCloseIcon'
// import CircleNextIcon from './CircleNextIcon'
// import CirclePlayIcon from './CirclePlayIcon'
// import CirclePrevIcon from './CirclePrevIcon'
// import ThreeDIcon from './3DIcon'
export const Icon = ({ id, ...props }) => {
  switch (id) {
    case ICON_IDS.OK:
      return <CheckIcon {...props} />
    case ICON_IDS.UP_STEP:
      return <KeyboardArrowUpIcon {...props} />
    case ICON_IDS.DOWN_STEP:
      return <KeyboardArrowDownIcon {...props} />
    case ICON_IDS.BLOCKED:
      return <LockIcon {...props} />
    case ICON_IDS.UNBLOCKED:
      return <LockOpenIcon {...props} />
    case ICON_IDS.FIT:
      return <FitScreenIcon {...props} />
    case ICON_IDS.RESIZE:
      return <HeightIcon {...props} />
    case ICON_IDS.ROTATE:
      return <RotateRightIcon {...props} />
    case ICON_IDS.TAKE_SCREENSHOT:
      return <CameraIcon {...props} />
    case ICON_IDS.IMPORT:
      return <SystemUpdateAltIcon {...props} />
    case ICON_IDS.RELOAD:
      return <CachedIcon {...props} />
    case ICON_IDS.EXPAND_MENU:
      return <ArrowForwardIosIcon {...props} />
    // case ICON_IDS.THREED:
    //   return <ThreeDIcon {...props} />
    case ICON_IDS.DIAGRAM:
      return <SchemaIcon {...props} />
    case ICON_IDS.MOVE:
      return <PanToolIcon {...props} />
    case ICON_IDS.MOUSE:
      return <MouseIcon {...props} />
    case ICON_IDS.SNAPSHOT:
      return <PhotoCameraIcon {...props} />
    case ICON_IDS.PANO_DIALOG:
      return <WysiwygIcon {...props} />
    case ICON_IDS.FULL_SCREEN:
      return <FullscreenIcon {...props} />
    case ICON_IDS.EXIT_FULL_SCREEN:
      return <FullscreenExitIcon {...props} />
    case ICON_IDS.INFO:
      return <InfoIcon {...props} />
    // case ICON_IDS.CIRCLE_NEXT:
    //   return <CircleNextIcon {...props} />
    // case ICON_IDS.CIRCLE_PREV:
    //   return <CirclePrevIcon {...props} />
    // case ICON_IDS.CIRCLE_PLAY:
    //   return <CirclePlayIcon {...props} />
    // case ICON_IDS.CIRCLE_CLOSE:
    //   return <CircleCloseIcon {...props} />
    case ICON_IDS.GALLERY:
      return <CollectionsIcon {...props} />
    case ICON_IDS.VIEW_UP:
      return <ArrowUpwardIcon {...props} />
    case ICON_IDS.VIEW_DOWN:
      return <ArrowDownwardIcon {...props} />
    case ICON_IDS.VIEW_LEFT:
      return <ArrowBackIcon {...props} />
    case ICON_IDS.VIEW_RIGHT:
      return <ArrowForwardIcon {...props} />
    case ICON_IDS.NEXT_POINT:
      return <KeyboardDoubleArrowRightIcon {...props} />
    case ICON_IDS.PREV_POINT:
      return <KeyboardDoubleArrowLeftIcon {...props} />
    case ICON_IDS.IMPORT_PREVIOUS:
      return <ArrowForwardIcon {...props} />
    case ICON_IDS.IMPORT_NEXT:
      return <ArrowBackIcon {...props} />
    case ICON_IDS.CAMERA:
      return <PhotoCameraIcon {...props} />
    case ICON_IDS.AUDIO:
      return <MusicNoteIcon {...props} />
    case ICON_IDS.AUTOROTATE:
      return <ThreeSixtyIcon {...props} />
    case ICON_IDS.HOME:
      return <HomeIcon {...props} />
    case ICON_IDS.ZOOM_OUT:
      return <RemoveIcon {...props} />
    case ICON_IDS.ZOOM_IN:
      return <AddIcon {...props} />
    case ICON_IDS.MUSIC_OFF:
      return <MusicOffIcon {...props} />
    case ICON_IDS.MUSIC_ON:
      return <MusicNoteIcon {...props} />
    // case ICON_IDS.SHOW_BAR:
    //   return <TriangleIcon {...props} />
    case ICON_IDS.HIDE_BAR:
      return <EjectIcon {...props} />
    case ICON_IDS.MAXIMIZE:
      return <WebAssetIcon {...props} />
    case ICON_IDS.MINIMIZE:
      return <MinimizeIcon {...props} />
    case ICON_IDS.PLAY_RECORDING:
      return <PlayArrowIcon {...props} />
    case ICON_IDS.PAUSE_RECORDING:
      return <PauseIcon {...props} />
    case ICON_IDS.START_RECORDING:
      return <FiberManualRecordIcon {...props} />
    case ICON_IDS.STOP_RECORDING:
      return <StopIcon {...props} />
    case ICON_IDS.BOTTOM_RIGHT_RESIZER:
      return <BottomRightResizerIcon {...props} />
    case ICON_IDS.RECORD:
      return <VideocamIcon {...props} />
    case ICON_IDS.NEXT_STEP:
      return <NavigateNextIcon {...props} />
    case ICON_IDS.PREV_STEP:
      return <NavigateBeforeIcon {...props} />
    case ICON_IDS.LAST_STEP:
      return <LastPageIcon {...props} />
    case ICON_IDS.FIRST_STEP:
      return <FirstPageIcon {...props} />
    case ICON_IDS.SELECTED:
      return <CheckBoxIcon {...props} />
    case ICON_IDS.SELECT_ALL:
      return <CheckBoxIcon {...props} />
    case ICON_IDS.DESELECT_ALL:
      return <CheckBoxOutlineBlankIcon {...props} />
    case ICON_IDS.REMOVE:
      return <DeleteIcon {...props} />
    case ICON_IDS.EDIT:
      return <EditIcon {...props} />
    case ICON_IDS.ADD:
    case ICON_IDS.DUPLICATE:
      return <AddIcon {...props} />
    case ICON_IDS.TEXT_EDITOR:
      return <DataObjectIcon {...props} />
    case ICON_IDS.NEW:
      return <InsertDriveFileIcon {...props} />
    case ICON_IDS.OPEN:
      return <FolderOpenIcon {...props} />
    case ICON_IDS.SAVE:
      return <SaveIcon {...props} />
    // case ICON_IDS.POINT:
    //   return <PointIcon {...props} />
    case ICON_IDS.LINK:
      return <InsertLinkIcon {...props} />
    case ICON_IDS.HOTSPOT:
      return <PlaceIcon {...props} />
    case ICON_IDS.SCENE:
      return <BurstModeIcon {...props} />
    case ICON_IDS.PREVIEW:
      return <PreviewIcon {...props} />
    case ICON_IDS.SELECTOR:
      return <TocIcon {...props} />
    // case ICON_IDS.POLYGONS:
    //   return <PolygonIcon {...props} />
    case ICON_IDS.MEDIA:
      return <PermMediaIcon {...props} />
    case ICON_IDS.BUILD:
      return <BuildIcon {...props} />
    case ICON_IDS.MAP_EDITOR:
      return <MapIcon {...props} />
    case ICON_IDS.MOVE_UP:
      return <ArrowUpwardIcon {...props} />
    case ICON_IDS.GOTO:
      return <GpsNotFixedIcon {...props} />
    case ICON_IDS.VISIBLE:
      return <VisibilityIcon {...props} />
    case ICON_IDS.HIDDEN:
      return <VisibilityOffIcon {...props} />
    case ICON_IDS.COLLAPSE:
      return <ExpandLessIcon {...props} />
    case ICON_IDS.EXPAND:
      return <ExpandMoreIcon {...props} />
    case ICON_IDS.LIST_MENU:
      return <MenuIcon {...props} />
    case ICON_IDS.ADD_POLYGON_POINT:
      return <AddBoxOutlinedIcon {...props} />
    case ICON_IDS.SETTINGS:
      return <SettingsIcon {...props} />
    case ICON_IDS.EQUIRECTANGULAR:
      return <PanoramaPhotosphereIcon {...props} />
    case ICON_IDS.MAP:
      return <MapIcon {...props} />
    case ICON_IDS.RADAR:
      return <SignalWifi4BarIcon {...props} />
    case ICON_IDS.ERROR:
      return <ErrorIcon {...props} />
    case ICON_IDS.CHECK:
      return <CheckIcon {...props} />
    case ICON_IDS.IMAGE_STRIP:
      return <Crop75Icon {...props} />
    case ICON_IDS.IMAGE:
      return <ImageIcon {...props} />
    case ICON_IDS.VIDEO:
      return <VideocamIcon {...props} />
    case ICON_IDS.CLOSE:
      return <CloseIcon {...props} />
    case ICON_IDS.TREE_COLLAPSE:
      return <RemoveIcon {...props} />
    case ICON_IDS.TREE_EXPAND:
      return <AddIcon {...props} />
    // case ICON_IDS.POLYGON:
    //   return <PolygonIcon {...props} />
    // case ICON_IDS.POINTER:
    //   return <PointerIcon {...props} />
    // case ICON_IDS.SPLIT_LINE:
    //   return <SplitLineIcon {...props} />
    case ICON_IDS.REMOVE_POLYGON_POINT:
      return <IndeterminateCheckBoxOutlinedIcon {...props} />

    default:
      return null
  }
}

export default Icon
